@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap");

* {
  font-family: "G&B-BodyCopy";
  box-sizing: border-box;
}
.ag-header-row {
  background: #707070 !important;
  color: #ffffff !important;
  height: 40px !important;
  /* font-weight: 600 !important; */
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-top:0.5rem;
}

.ag-pinned-right-header .ag-header-row,
.ag-pinned-right-cols-container .ag-row {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ag-pinned-right-cols-container .ag-row .ag-cell {
  border-left: none !important;
}

.ag-row-hover,.ag-row:hover {
  border: 1px solid #707070 !important;
  border-left: none !important;
}

.ag-cell-focus {
  border: none !important;
}

.ag-row {
  border-radius: 4px 0px 0px 4px;
  /* margin-top: 0.5rem !important; */
  height: 48px !important;
}

/* .cell-action {
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: space-between;
} */

.ag-row-hover .cell-action {
  opacity: 1;
  visibility: visible;
  display: flex;
  justify-content: space-between;
}

.modal-fullscreen .modal-body,
.ag-root-wrapper-body,
.ag-header-viewport {
  background-color: #fff !important;
}
.ag-theme-material {
  margin-top: 0rem;
}
.modal-body{
  padding: 1px 10px 5px 10px;
}
/* .ag-theme-material */
.ag-row-odd {
  background-color: #fff !important;
}

.ag-theme-material .ag-root-wrapper {
  background-color: #fff !important;
}

.ag-paging-panel,
.ag-pinned-right-header {
  border: none !important;
  background-color: #ffffff;
}

.ag-paging-description {
  text-align: left;
  font: normal normal medium 14px/24px "G&B-BodyCopy" !important;
  letter-spacing: 0px !important;
  color: #707070 !important;
  opacity: 1 !important;
}

.filter-col {
  height: 35px;
  font-size: 14px;
  max-width: max-content;
  background-color: #dfe5f8;
  border-radius: 15px;
  display: flex;
  place-items: center;
  justify-content: space-around;
}

.ag-center-cols-clipper {
  min-height: 200px !important;
  background-color: #ffffff;
}

/* filter ------------*/
.ag-theme-material .ag-filter-apply-panel {
  padding: 0px 12px 12px !important;
}

.ag-theme-material .ag-simple-filter-body-wrapper > * {
  margin-bottom: 8px !important;
}
.readonly-fields
{
  background-color: #ffffff;
  font-size: 14px;
  color: #6e767c;
}

/*==================*/
.ag-pinned-right-cols-container{
  background-color: #ffffff;
}
.ag-row-hover {
  background-color: #f9f2f6 !important;
  /* border-color: #707070; */
  /* border: 2px solid red !important; */
  /* border-style: solid;
  border-color: red;
  border-width: 2px; */
}

