$aemWhite: #ffffff;
$aemBlue: #264AE4;
$aemRed: #E1142E;
$aemGreen: #3CB23D;
$aemGreenLight: #6ED82C;
$aemLightRed: #FEF1F1;
$aemBlack: #333333;
$aemGrey: #A0A0A0;
$aemLogin: #C5CADB;
$dropBorder: #E2E2E2;
$tabBdBg: #F5F5F5;
$dropBg: #F8F8F8;
$dropIconBg: #DFE5F8;
$roiTableHeader: #CBD4F0;
$roiTableInput: #F8F7D5;
$roiTableInputBorder: #525252;
