@import "./components/appArun";
@import "./components/table";
@import "./components/timeline";
@import "./components/customPages";
@import "./layout/color";
@import "./layout/resmixins";
@import "./pages/login";
@import "./pages/userMaster";
@import "./pages/common";
@import "./pages/dashboard";

@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap");
@import "./pages/font.scss";

body {
  margin: 0;
  padding: 0;
}

* {
  font-family: "G&B-BodyCopy";
  box-sizing: border-box;
}

.app-content {
  padding: 1rem;
  height: calc(100vh - 5.5rem);
  background-color: #ffffff;
}

.app-content-mob {
  padding: 1rem;
  height: calc(100vh - 17.5rem);
}

// $device-md
.action-area {
  background-color: #fff;
  padding: 8px 0.5rem 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  flex-flow: row;
}

.action-area1 {
  //background-color: #dfe5f8;
  background-color: white;
  height: 3rem;
  // padding: 8px 0.5rem 8px;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // flex-flow: row;
}

.btn-primary {
  background-color: #810055;
  border-radius: 20rem;
  border-color: #810055;
  // width: 5.5rem;
}

.btn-add {
  background-color: #810055;
  width: 5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.375rem 0.75rem;
  border: 1px solid;
  text-decoration: none;
  border-radius: 1.25rem;
  color: #fff;
  border-color: #810055;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
}

.action-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  border: 1px solid #810055;
  text-decoration: none;
  // border-radius: 1rem;
  background-color: #810055;
  border-color: #810055;
  width: 95%;
}

.btn-text {
  font-size: 14px;
  line-height: 1;
  text-align: left;
  // font-weight: 600;
  margin-right: 1.25rem;
}

.btn-text1 {
  font-size: 9px;
  line-height: 1;
  text-align: left;
  // font-weight: 600;
  margin-right: 1.25rem;
}

.btn-text2 {
  font-size: 12px;
  line-height: 1;
  text-align: center;
  // font-weight: 600;
}

.btn-text-cm {
  font-size: 18px;
  line-height: 1;
  text-align: left;
  // font-weight: 600;
  // margin-right: 0.5rem;
}

.reset-btn {
  margin-left: 0.5rem;
  outline: none;
  border: none;
  background-color: #ffffff;
  color: #810055;
  font-size: 0.875rem;
}

//.modal :is(.form-control) {
//background-color: #7F86A0;
//background-color: rgb(248,248,248);
//border: 1px solid #e2e2e2;
//}

.modal :is(.form-control):focus {
  background-color: #ffffff;
  //border: 1px solid #e2e2e2;
}

// .modal-open .modal-footer .btn {
//   // width: 5rem;
// }
// background-color: #dfe5f8;
// padding: 8px 0.5rem 8px;
// display: flex;
// align-items: center;
// justify-content: space-between;
// height: 3rem;
// flex-flow: row;

.modal-footer-country {
  justify-content: flex-start !important;
  background-color: #fff;
  // position: fixed;
  bottom: -5rem;
  background: #fff;
  width: 100%;
  margin-bottom: 1rem;
}

.modal-footer {
  //justify-content: flex-start !important;
  background-color: #fff;
}

.modal-footer-report {
  //justify-content: flex-start !important;
  background-color: #fff;
}

.confirmcss {
  align-items: center;
  text-align: center;
  // font-weight: bold;
}

.status-color {
  width: 0.5rem;
  min-width: 0.5rem;
  // height: 100%;
  height: 44px;
  border-radius: 4px 0px 0px 4px;
  margin-right: 0.5rem;
}

.status-color-mob {
  width: 0.5rem;
  min-width: 0.5rem;
  height: 100%;
  border-radius: 4px 0px 0px 4px;
  margin-right: 0.5rem;
}

.bread-crumb {
  height: 1.5rem;
  background-color: #ffffff;
  padding: 0.3rem 1rem;
  border-bottom: 1px solid #f1f1ed;
}

.breadcrumb-item a {
  text-decoration: none !important;
  color: #707070;
}

.breadcrumb {
  margin: 0 !important;
  font-size: 10px;
  // font-weight: 400;
}
.breadcrumb-item.active {
  font-weight: bold;
  color:#707070;
}

.table-container {
  overflow: auto;
  padding: 0.5rem 0;
}

.table-container1 {
  overflow: auto;
}
.table-container-db {
  width: 203%;
}

.background {
  background-color: #fff;
}

.table-head,
.table-body,
.table-row,
.table-row1,
.table-row2,
.table-cell1,
.table-cell {
  display: flex;
  //padding: 0.2rem 0;
  padding: 0;
}

.scroll-content {
  .row {
    justify-content: space-between;
  }
}

.table-head {
  width: auto;
  // width: fit-content;
  // width: 100%;
  flex-flow: column;
}
.table-head1 {
  background-color: #fff;
  flex-flow: column;
}
.table-head2 {
  width: 50%;
  background-color: #fff;
  flex-flow: column;
}

@media screen and (min-width: 1550px) and (max-width: 1800px) {
  .table-head {
    // width: max-content;
    // width: 100%;
    flex-flow: column;
    min-width: 100%;
    max-width: 106%;
  }

  .extended-table {
    min-width: 11.5rem !important;
    max-width: 11.5rem !important;
  }
}

@media screen and (min-width: 1801px) and (max-width: 3000px) {
  .table-head {
    // width: max-content;
    // width: 100%;
    flex-flow: column;
    min-width: 100%;
    max-width: 106%;
  }

  .extended-table {
    min-width: 13.5rem !important;
    max-width: 13.5rem !important;
  }
}

.table-cell {
  min-width: 140px;
  max-width: 140px;
  font-size: 14px;
  margin-left: 1rem;
  text-align: left;
  align-items: center;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    white-space: break-spaces;
    // overflow: hidden;
    // text-overflow: ellipsis;
    display: block;
    //width: 100px;
  }
}

.table-cell1 {
  // min-width: 140px;
  // max-width: 140px;
  font-size: 14px;
  margin-left: 1rem;
  text-align: left;
  align-items: center;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    white-space: break-spaces;
    // overflow: hidden;
    // text-overflow: ellipsis;
    display: block;
    //width: 100px;
  }
}

.table-cell2 {
  min-width: 125px;
  width: 25%;
  /* max-width: 140px; */
  font-size: 14px;
  margin-left: 3rem;
  text-align: left;
  align-items: center;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    white-space: break-spaces;
    // overflow: hidden;
    // text-overflow: ellipsis;
    display: block;
    //width: 100px;
    color: #ffffff;
  }
}

.tableCellCustom {
  padding-left: 0.4rem;
}
.rowWidth {
  width: 62vw;
}

.rowWidth1 {
  width: 100vw;
}

.table-body .table-cell {
  color: #707070;
}

.table-body {
  // margin: 0px;
  // width: fit-content;
  // width: 100%;
  // flex-direction: column;
  width: fit-content;
  flex-flow: column;
}

.table-body1 {
  //background-color: #fff;
  flex-flow: column;
}

.table-body2 {
  //background-color: #fff;
  //width: 50%;
  min-width: 100%;
  max-width: 100%;
  flex-flow: column;
  display: table-cell;
}

.table-row {
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 48px;
  min-height: 48px;
  //padding: 0.2rem;
  padding: 0.1rem;
  border-radius: 5px;
  border: 1px solid #f1f1ed;
}

.table-head .table-row {
  // height: 40px;
  //background-color: #fff;
  background: #707070 !important;
  //padding: 4px 0.5rem 4px;
  border-radius: 4px;
  color: #fff;
}

// .table-head :hover{
//   background-color: grey;
// }

.table-row1 {
  // height: 40px;
  background-color: #fff;
  //padding: 4px 0.5rem 4px;
  border-radius: 4px;
  // font-weight: 600;
}

.table-row2 {
  //height: 40px;
  background-color: #fff;
  //padding: 4px 0.5rem 4px;
  border-radius: 4px;
  // font-weight: 600;
  display: flex;
  align-items: center;
}

.table-head1 {
  // height: 40px;
  background-color: #fff;
  //padding: 4px 0.5rem 4px;
  border-radius: 4px;
  // font-weight: 600;
  // color: #a0a0a0;
}

.customRowGraph {
  padding: 4px 0rem 4px !important;
}

.table-body .table-row .table-row1 .table-row2 {
  min-height: 48px;
  margin-top: 0.5rem;
  // font-weight: 500;
  transition: all 300ms;
  border-radius: 5px 5px 5px 5px;
}

.cell-action {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  opacity: 0;
  visibility: hidden;
}

// .table-body .table-row:hover {
//   /* transform: scale(1.05); */
//   /* box-shadow: 1px 10px 10px -10px rgba(0, 0, 24, 0.5); */
//   // border: 1px solid #264ae4;
// }

.table-body .table-row:hover > .cell-action {
  opacity: 1;
  visibility: visible;
}

.form-switch .form-check-input:checked {
  background-color: #33c037;
  border-color: #33c037;
}

.form-body {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.form-label {
  // font-weight: 600;
  color: #707070;
  //color: #5A5A5A;
  font-size: 14px;
}

.req-input {
  font-style: italic;
  color: #e1142e;
  font-size: 11px;
  // font-weight: 500;
  margin-left: 4px;
}

.trans-all-man-fields {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0.1rem 0.1rem 0.1rem;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  font-style: italic;
  color: #e1142e;
  font-size: 11px;
  margin-left: 4px;
}

.form-switch-input .form-check-label {
  margin-left: 10px;
  //color: #a7aec6;
  color: #707070;
}

.form-switch .form-check-input:checked + .form-check-label {
  color: #33C037;
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 920px !important;
  }
}

/* Global Search */
.global-search-container {
  //margin: 0 1rem;
  display: flex;
  align-items: center;
  flex-grow: 1;
  background-color: #fff;
}

.global-search-box {
  //padding: 0.5rem 1rem;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
}

.global-search-box.show {
  border-bottom: 1px solid #f1f1ed;
}

/* .global-search-box:hover>input:placeholder-shown,
.global-search-box input:not(:placeholder-shown) {
    padding: 0 10px;
} */

.global-search-box input {
  border: none;
  outline: none;
  padding: 0;
  background: none;
  transition: 0.5s ease;
}

.global-search-box.show input {
  flex-grow: 1;
  display: block;
  padding: 0 1rem;
}

.global-search-box input:placeholder-shown {
  width: 0;
}

.global-search-box ::placeholder {
  color: #a7aec6;
}

.pagintion-bar {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 5px;
  height: 1rem;
  // font-weight: 500;
  font-size: 14px;
  color: #525252;
  padding-bottom: 1.5rem;
}

.pagintion-bar svg {
  cursor: pointer;
  color: #707070;
}

.pagintion-bar * {
  margin-right: 12px;
}

.pagintion-bar .disabled {
  pointer-events: none;
  color: #b5b9c6;
}

.header .dropdown-menu {
  right: 5px;
  top: 40px;
}

// .form-evaluation {
//   column-width: 10rem;
// }

.no1req-input {
  /* font-style: italic; */
  color: #707070;
  font-size: 10px;
  // font-weight: 500;
  padding-top: 0%;
  padding-bottom: 0%;
}

.form-control-padding {
  padding: 0.2rem 0.75rem;
  background-color: #fff;
}

// .form-control-color {
//   padding: 0.2rem 0.75rem;
//   background-color: #F8F7D5;
// }
// .form-control:disabled, .form-control[readonly] {
//   background-color: #F8F7D5;
//   opacity: 1;
// }
// .form-control{
//   background-color: #EAEEFF;
// }

.form-control1-padding {
  padding: 0.2rem 0.75rem;
  background-color: #fff;
  border: 1px solid #f1f1ed;
}

// .form-control-color {
//   padding: 0.2rem 0.75rem;
//   background-color: #F8F7D5;
// }
.form-control1:disabled,
.form-control1[readonly] {
  background-color: #fff;
  opacity: 1;
  border: 1px solid #f1f1ed;
}

.form-control1 {
  background-color: #fff;
}

.form-control2-padding {
  padding: 0.2rem 0.75rem;
  background-color:#fff;
}

// .form-control-color {
//   padding: 0.2rem 0.75rem;
//   background-color: #F8F7D5;
// }
.form-control2:disabled,
.form-control2[readonly] {
  background-color: #fff;
  opacity: 1;
  border: 1px solid #f1f1ed;
}

.form-control2 {
  background-color: #7f86a0;
}

.place-center-eq {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.place-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.place-center1 {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding-bottom: 10px;
}

.place-right {
  display: flex !important;
  align-items: center;
  justify-content: space-evenly;
}

.place-end {
  display: flex !important;
  justify-content: end;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dzu-dropzone {
  min-height: 56px !important;
  overflow: hidden !important;
  border: 2px dashed #f1f1ed !important;
}

.dzu-inputLabel {
  font-size: smaller !important;
  background-color: #fff;
}

.circle {
  display: grid;
  place-items: center;
  height: 33px;
  width: 33px;
  border-radius: 50%;
  background-color: rgba(38, 74, 228, 0.15);
  position: absolute;
  bottom: 11px;
  left: 24px;
  box-shadow: 0px 0px 0px 4px #fff, 0px 0px 0px 5px rgba(38, 74, 228, 0.15);
}

.text-danger {
  font-size: smaller !important;
}

.dropZoneContainer {
  position: relative;
  background: #ffffff;
  border: 2px dashed #f1f1ed;
  border-radius: 5px;
  display: flex;
  // height: 50px;
  // padding-top: 5px;
}

.form-select {
  // background: #ffffff;
  background-image: url(./images/whiteArrow.svg);
  background-size: 24px 24px;
  //font-size: 12px;
}

.form-select-ucm {
  // background: #ffffff;
  background-image: url(./images/DownArrowGrey.svg);
  background-size: 24px 24px;
  font-size: 12px;
  color: #333333;
}

.form-select-ucm1 {
  // background: #ffffff;
  background-image: url(./images/DownArrowGrey.svg);
  background-size: 12px 12px;
  // font-weight: 200;
  //font-size: 12px;
}

.form-select-ba {
  // background: #ffffff;
  background-image: url(./images/blackarrow.svg);
  background-size: 24px 24px;
  // font-weight: 400;
  font-size: 12px;
  border:1px solid #f1f1ed;
}

.form-select-IFC {
  // background: #ffffff;
  background-image: url(./images/DownArrowGrey.svg);
  background-size: 24px 24px;
  font-size: 16px;
}

.dropOuterCircle {
  border: 1px solid #f1f1ed;
  width: 34px;
  height: 34px;
  // margin: 8px 40px 8px 30px;
  margin: 8px 10px 8px 30px;
  border-radius: 50%;
  text-align: center;

  .dropInnerCircle {
    border: 1px solid #f1f1ed;
    background: #ffffff;
    width: 28px;
    height: 28px;
    margin: 2px;
    border-radius: 50%;
    text-align: center;
  }
}

.dropTextContainer {
  font-size: 14px;
  // margin-top: 12px;
  // margin-bottom: 12px;
  // padding-top: 5px;
  vertical-align: middle;
  // margin-left: 12px;
  // font-weight: 500;
  // margin: 10px 0 10px 12px;
  margin: 12px 0 10px 0px;
  display: flex;
  align-items: center;

  .dropTextBlue {
    color: #810055;
    text-decoration: underline;
    cursor: pointer;
    padding-left: 5px;
  }
  .nav-link {
    padding: 0rem 1rem;
    color: #810055;
  }
}

.dropTextContainer1 {
  font-size: 14px;
  // margin-top: 12px;
  // margin-bottom: 12px;
  vertical-align: middle;
  margin-left: 12px;
  // font-weight: 500;

  .dropTextBlue {
    color: #810055;
    text-decoration: underline;
    cursor: pointer;
  }
}

.dropLeftDiv {
  display: flex;
  right: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  align-items: center;
  vertical-align: middle;

  .dropLeftUploadPercent {
    font-size: 14px;
    // margin-top: 15px;
    margin-right: 5px;
  }

  .dropLeftInnerCircle {
    cursor: pointer;
    border: 1px solid #f1f1ed;
    background: #fff;
    width: 28px;
    height: 28px;
    margin: 8px;
    border-radius: 50%;
    text-align: center;
  }

  .dropLeftGreenCircle {
    border: 1px solid #33C037;
    background: #33C037;
    width: 28px;
    height: 28px;
    margin: 8px;
    border-radius: 50%;
    text-align: center;
  }

  .dropLeftIcons {
    width: 28px;
    height: 28px;
    margin: 0px 8px;
    text-align: center;
  }

  .dropDeleteIcon {
    cursor: pointer;
  }
}

.uploadNavlink {
  padding: 0px;
  text-decoration-line: underline;
  color:#810055;
}

.tableHeadCompressorModal {
  width: fit-content;
}

.tableBodyCompressorModal {
  .table-row {
    background: #fff;
    border: 1px solid #f1f1ed;
  }

  .table-row1 {
    background: #fff;
  }

  width: fit-content;
}

//Compline scroll bar
.scroll-compline {
  //overflow-x: hidden;
  //overflow-y: auto !important;
  //overflow: auto !important;
  max-height: 210px !important;
}

// react-flow-diagram-- vishal

.text-updater-node {
  width: 38px;
  height: 38px;
  border-radius: 60px;
  display: grid;
  place-items: center;
  background: #810055 0% 0% no-repeat padding-box;
  opacity: 1;
  box-shadow: 0px 0px 0px 3px #8A497B,
    0px 0px 0px 6px #8F6A8C;
}

.upc-node {
  width: 38px;
  height: 38px;
  border-radius: 60px;
  display: grid;
  place-items: center;
  background: #4bb6e8 0% 0% no-repeat padding-box;
  opacity: 1;
  box-shadow: 0px 0px 0px 3px rgba(75, 142, 232, 0.3),
    0px 0px 0px 6px rgba(75, 142, 232, 0.31);
}

.compressor-node {
  width: 80px;
  height: 65px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #4545454d;
  border: 2px solid #810055;
  border-radius: 8px;
  display: grid;
  place-items: center;
  opacity: 1;
  z-index: 1;
}

.application-node {
  width: 75px;
  height: 75px;
  // background: #FFE3C3 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #4545454d;
  // border: 2px solid #EF802F;
  border-radius: 60px;
  display: grid;
  place-items: center;
  opacity: 1;
  z-index: 1;
}

.text-updater-node label {
  text-align: center;
  font: normal normal 8px/8px 'G&B-BodyCopy';
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.upc-node label {
  text-align: center;
  font: normal normal 8px/8px 'G&B-BodyCopy';
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.compressor-node label {
  font: normal normal 10px/11px 'G&B-BodyCopy';
  letter-spacing: 0px;
  color: #810055;
  opacity: 1;
}

.application-node label {
  font: normal normal 10px/11px 'G&B-BodyCopy';
  letter-spacing: 0px;
  // color: #EF802F;
  opacity: 1;
}

.react-flow__attribution.bottom.right {
  display: none;
}

.react-flow-container {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 4px;
  background-size: 15px 15px;
  background-image: linear-gradient(to right, #f2f2f2 1px, transparent 1px),
    linear-gradient(to bottom, #f2f2f2 1px, transparent 1px);
}

.react-flow__edge-textbg {
  border: 2px solid black !important;
  height: 15px !important;
  width: 15px !important;
  background-color: #33C037;
  border: 1px solid #f1f1ed;
}

// .errorBorder {
//   border: 2px solid $aemRed;
// }

.topLeftGreen {
  position: absolute;
  height: 35px;
  min-width: 172px;
  padding: 4px;
  gap: 6px;
  background: #33C037;
  font-size: 10px;
  display: inline-flex;
  color: #fff;
  // border-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;

  .leftContainer {
    border-right: 1px solid white;
    padding-right: 6px;
    text-align: center;
  }

  .rightContainer {
    text-align: center;
  }
}

.bottomright {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 102px;
  height: 54px;
  display: grid;
  justify-content: end;
  padding: 5px;
}

.dot-circle {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 1px solid #f1f1ed;
  display: inline-block;
  margin-right: 4px;
}

.dot-text {
  font-size: 12px;
  text-align: left;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.eq-text {
  font-size: 12px;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  position: absolute;
  height: 25px;
  min-width: 172px;
  padding: 4px;
  gap: 6px;
  margin-top: 25px;
  display: inline-flex;
  // border-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.text-field-with-error {
  border-color: #e1142e !important;
}

.text-field-with-error:focus {
  border-color: #e1142e;
  outline: #e1142e;
  box-shadow: none;
}

// react-flow-diagram-- vishal
.clear-all-btn {
  text-align: center;
  font-size: 15px;
  padding-top: 8px;
  place-items: center;
}

.compressorHeaderSelect {
  padding: 0px;
  padding-left: 4px;
  margin-top: 4px;
  border-radius: 4px;
  font-size: 14px !important;
}

.compressorHeaderSelectWrapper {
  margin-left: 16px;
  margin-top: 2px;
}

.mobileCompressorModal {
  .modal-content {
    background: transparent;
    margin-top: 3rem;

    .modalCloseButton {
      background: $aemWhite;
      position: absolute;
      height: 26px;
      width: 26px;
      text-align: center;
      border-radius: 50%;
      right: 0;
      top: -20px;
      cursor: pointer;
    }
  }
}

.showFull {
  max-height: 165px;
  overflow: hidden;
}

.showLess {
  max-height: 100%;
}

.mobileTableModal {
  .tableBodyCompressorModal {
    width: 100%;
  }

  .mobileTableRow {
    display: grid;
    gap: 1px;

    .table-cell {
      flex-direction: row;
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100% !important;

      span {
        width: 60%;
        color: $aemBlack;
        //font-weight: 600;
        word-break: break-word;
        margin-top: 0.5rem;
        float: left;
      }

      // span.bold-Text {
      //   font-weight: 600;
      // }

      .span-text {
        width: 48%;
        color: $aemBlack;
        word-break: break-all;
      }
    }
  }

  .showButton {
    // position: absolute;
    // bottom: 0;
    text-align: center;
    width: 100%;
    font-size: 12px;
    text-decoration: underline;
    color: #810055;
    cursor: pointer;
    border-radius: 10px;
  }
  .showButton1 {
    // position: absolute;
    // bottom: 0;
    text-align: center;
    width: 100%;
    font-size: 12px;
    text-decoration: underline;
    color: #810055;
    cursor: pointer;
    border-radius: 10px;
  }

  .mobileTableRowEdit {
    display: grid;
    gap: 1px;

    .table-cell {
      display: grid;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      padding-right: 30px;

      span {
        color: $aemBlack;
        margin-bottom: 6px;
        margin-top: 1rem;
      }

      input {
        padding: 6px;
        font-size: 16px;
      }

      select {
        padding: 6px;
        font-size: 16px;
        height: inherit;
        border: 1px solid #707070;
      }
    }

    .tableCellPopup {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }

  .tableButtons {
    position: static !important;
  }

  .table-cell-mobile-input {
    display: grid !important;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding-right: 30px;

    span {
      color: #333333;
      // font-weight: 600;
      margin-bottom: 6px;
      margin: auto;
    }

    input {
      padding: 6px;
      font-size: 16px;
      margin-bottom: 12px;
      margin-top: 7px;
    }

    select {
      padding: 6px;
      font-size: 16px;
      height: inherit;
    }
  }

  .table-cell-mobile-input1 {
    width: 100%;
    display: grid;
    min-width: 100%;
    max-width: 100%;
    //padding-right: 10px !important;
    //margin-right: 10px;

    span {
      color: #333333;
      margin-bottom: 6px;
      margin: auto;
    }

    input {
      padding: 6px 6px;
      font-size: 16px;
      //margin-bottom: 12px;
      margin-top: 7px;
    }

    select {
      padding: 6px;
      font-size: 16px;
      height: inherit;
    }
  }
}

.compressorModalHeader {
  .modal-header {
    padding: 1rem 1rem 0.3rem 1rem;
  }

  span {
    margin-left: 0px;
  }
}

#ApplicationDets,
#NetworkDets {
  .trans-all-man-fields {
    margin-left: 14px;
  }
}

.mandBody {
  .trans-all-man-fields {
    margin-left: 0px;
    padding: 0rem 0rem 0.1rem;
  }
}

.content-align {
  padding-left: 0;
}

.option-select {
  // width: 104px;
  // height: 112px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
}

.statusCell {
  color: #33C037 !important;
  // font-weight: 800;
}

.tableCellGreen {
  color: #33C037 !important;
}

.tableCellGrey {
  color: #707070 !important;
}

.ag-row:hover {
  border: none !important;
}

// .ag-row {
//   border: none !important;
// }

// .loader-container {
//   width: 100%;
//   height: 100vh;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: fixed;
//   // background: rgba(0, 0, 0, 0.834);
//   background: rgba(255, 255, 255, 0.3);
//   z-index: 1;
// }

// .spinner {
//   width: 64px;
//   height: 64px;
//   border: 8px solid;
//   border-color: #3d5af1 transparent #3d5af1 transparent;
//   border-radius: 50%;
//   animation: spin-anim 1.2s linear infinite;
//   top : 50%;
//   left: 50%;
//   position: absolute;
// }

// @keyframes spin-anim {
//   0% {
//     transform: rotate(0deg);
//   }

//   100% {
//     transform: rotate(360deg);
//   }
// }

//Loading spinner
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 44px;
  height: 44px;
  margin: 6px;
  border: 6px solid #0d9dd7;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0d9dd7 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
}

// .modal-header {
//   .btn-close {
//     padding: 0.5rem 0.5rem;
//     margin: -0.5rem -0.5rem -0.5rem auto;
//     margin-top: -6%;
//   }
//}

// @media (max-width: 767px) {
//   .modal-footer-country {
//     width: 91.5% !important;
//   }

//   .table-cell {
//     .row {
//       .col-4 {
//         width: 39%;
//       }

//       .col-8 {
//         width: 55%;
//       }

//       .col-4,
//       .col-5 {
//         span {
//           color: black;
//           font-weight: bold;
//         }
//       }

//       .col-4,
//       .col-5,
//       .col-7,
//       .col-8 {
//         span {
//           width: 100% !important;
//         }
//       }
//     }
//   }

//   .table-cell {
//     min-width: 140px;
//     max-width: 140px;
//     font-size: 13px;
//     margin-left: 1rem;
//     text-align: left;
//     align-items: center;
//     white-space: break-spaces;
//     overflow: hidden;
//     text-overflow: ellipsis;

//     span {
//       white-space: break-spaces;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       display: block;
//       width: 200px;
//       word-break: break-word;

//     }
//   }

//   .modal-footer-country {
//     justify-content: flex-start !important;
//     background-color: #fff;
//     position: fixed;
//     bottom: 0rem;
//     background: #FFF;
//   }

//   .cell-action {
//     position: absolute;
//     right: 1rem;
//     top: 0.5rem;
//     opacity: 1;
//     visibility: visible;
//   }
// }

.modal-backdrop {
  z-index: 0 !important;
}
// .newEnquiry label {
//   font-weight: 600;
// }
.test-tabs {
  .btn-sm {
    padding: 0rem 0.5rem;
  }
}
//==============================
.ag-theme-material .ag-row {
  border: 1px solid #f1f1ed !important;
  // border-left: none !important;
}
// .ag-theme-material .ag-row:hover{
//   border: 1px solid #707070 !important;
//   // border-left: none !important;
//   background-color: #f9f2f6 !important;
// }
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #810055;
  border-color: #810055;
  box-shadow: none !important;
}
.form-check-input:checked {
  background-color: #810055;
  border-color: #810055;
  box-shadow: none;
}
.form-select:focus{
  box-shadow: none;
  border-color: #f1f1ed;
}
input[type=checkbox]:checked {
  accent-color: #810055;
}
.ag-theme-material .ag-standard-button {
  color: #810055 !important;
}
.uploadNavlink:hover{
  color: #810055;
}
.ag-theme-material .ag-cell.ag-cell-first-right-pinned {
  border-right: 1px solid #f1f1ed !important;
}
/* width */
::-webkit-scrollbar,
.ag-body-horizontal-scroll-viewport,
.ag-horizontal-right-spacer {
  width: 10px;
  height: 10px;
}
 
/* Track */
::-webkit-scrollbar-track {
  background-color: white;
  /* -webkit-box-shadow: inset 0 0 6px #333(0,0,0,0.3) !important;  */
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 5px;
  /* box-shadow: inset 0 0 5px grey;
  border-radius: 5px; */
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  /* border-radius: 10px; */
  background: #707070;
  /* border-radius: 5px; */
}
.form-check-input:focus {
  border-color: #707070; 
  box-shadow: none; 
}
.form-check-input{
  border-color: #707070;
  border: 1.5px solid #f1f1ed;
}
.table-row:hover{
  background-color: #f9f2f6;
}
// .tableBodyCompressorModal {
//   .table-row :hover{
//     background: #fff !important;
//     border: 1px solid #f1f1ed !important;
//   }
// }
.form-control{
  color: #707070;
}
.form-select{
  color: #707070;
}
.tableBodyCompressorModal .table-row:hover {
  background: #fff;
  border: 1px solid #f1f1ed;
}