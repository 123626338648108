@import "../layout/color";

body{
    margin-top:20px;
    background:#FBFDFF
}
.cbp_tmtimeline {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative
}

// .cbp_tmtimeline:before {
//     content: '';
//     position: absolute;
//     top: 30px;
//     bottom: 0;
//     width: 1px;
//     background: $aemBlue;
//     left: 4%;
//     margin-left: -6px;
//     // height: calc(100% - 5.5rem)
// }

.cbp_verticalline:before{
    content: '';
    position: absolute;
    top: 30px;
    bottom: 0;
    width: 1px;
    background: $aemBlue;
    left: 4%;
    // margin-left: -6px;
    margin-left: -19px;
    height: 8rem;
}


.cbp_dottedline:before{
    content: '';
    position: absolute;
    top: 30px;
    bottom: 0;
    width: 1px;
    // background: $aemBlue;
    left: 3%;
    margin-left: -6px;
    height: 8rem;
    border-left: 1px dashed;
    color: $aemBlue;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
}

.height6:before{
    height: 6rem !important;
}
.left43{
    left: 4.3% !important;
}

.timHed{
    // font-size: 20px;
    // font-weight: 800;
    font: normal normal 16px/24px 'G&B-BodyCopy';
}
.timHedSma{
    // font-size: 14px;
    color: grey;
    font: normal normal normal 11px/11px 'G&B-BodyCopy';
}
.timHedRem{
    // font-size: 12px;
    color: #6F6F6F;
    width: max-content;
    font: normal normal normal 12px/11px 'G&B-BodyCopy';
}

.italic{
font: italic normal normal 12px/16px 'G&B-BodyCopy';
}

.timHedRemHed{
    font-size: 14px;
    color: rgb(79, 79, 79);
    // font-weight: 500;
}
.timHedSec{
    // font-size: 14px;
    font: normal normal normal 11px/11px 'G&B-BodyCopy';
    color: grey;
    padding-right: 0px;
}
.timHedTrd{
    // font-size: 12px;
    font: normal normal normal 10px/11px 'G&B-BodyCopy';
    color: #6F6F6F;
    background: #E2E2E2 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 2px 5px;
    text-align: center;
    letter-spacing: -0.05px;
    width: fit-content;
    margin-top: 1rem;
}
.timHedTrdA{
    font: normal normal normal 11px/11px 'G&B-BodyCopy';
    color: #6F6F6F;
    // background: #E1E9C7;
    background: #E1E9C7 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 2px 5px;
    text-align: center;
    letter-spacing: -0.06px;
    width: fit-content;
    margin-top: 1rem;
}
.timHedTrdR{
    // font-size: 12px;
    color: #FF0000;
    // background: #FEF1F1;
    background: #FEF1F1 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 2px 5px;
    text-align: center;
    font: normal normal normal 11px/11px 'G&B-BodyCopy';
    letter-spacing: -0.06px;
    width: fit-content;
    margin-top: 1rem;
}
.timHedTrdI{
    // font-size: 12px;
    color: #6F6F6F;
    background: #DFE5F8 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 2px 5px;
    text-align: center;
    font: normal normal normal 11px/11px 'G&B-BodyCopy';
    letter-spacing: -0.06px;
    width: fit-content;
    margin-top: 1rem;
}
.cbp_tmtimeline>li {
    position: relative
}

.cbp_tmtimeline>li:first-child .cbp_tmtime span.large {
    color: #444;
    font-size: 17px !important;
    // font-weight: 700
}

// .cbp_tmtimeline>li:first-child .cbp_tmicon {
//     background: #fff;
//     color: #666
// }

.cbp_tmtimeline>li:nth-child(odd) .cbp_tmtime span:last-child {
    color: #444;
    font-size: 13px
}

.cbp_tmtimeline>li:nth-child(odd) .cbp_tmlabel {
    background: $aemWhite
}

.cbp_tmtimeline>li:nth-child(odd) .cbp_label {
    background: $aemWhite
}

.cbp_tmtimeline>li:nth-child(odd) {
    border-right-color: $aemWhite
}

.cbp_tmtimeline>li .empty span {
    color: #777
}

.cbp_tmtimeline>li .cbp_tmtime {
    display: block;
    width: 23%;
    padding-right: 70px;
    position: absolute
}

.cbp_tmtimeline>li .cbp_tmtime span {
    display: block;
    text-align: right
}

.cbp_tmtimeline>li .cbp_tmtime span:first-child {
    font-size: 15px;
    color: #3d4c5a;
    // font-weight: 700
}

.cbp_tmtimeline>li .cbp_tmtime span:last-child {
    font-size: 14px;
    color: #444
}

.cbp_tmtimeline>li .cbp_tmlabel {
    // margin: 0 16px 15px 78px;
    margin: 0 1.3% 1.2% 6.5%;
    background: $aemWhite;
    padding: 1.2em;
    position: relative;
    border-radius: 5px;
    // width: 100%;
}

.cbp_tmtimeline>li .cbp_label {
    // margin: 0 16px 15px 78px;
    margin: 0 1.3% 1.2% 6.5%;
    background: $aemWhite;
    padding: 1.2em;
    position: relative;
    border-radius: 5px;
    // width: 100%;
}

// .cbp_tmtimeline>li .cbp_tmlabel:after {
//     right: 100%;
//     border: solid transparent;
//     content: " ";
//     height: 0;
//     width: 0;
//     position: absolute;
//     pointer-events: none;
//     border-right-color: $aemWhite;
//     border-width: 10px;
//     top: 10px
// }

.cbp_tmtimeline>li .cbp_tmlabel blockquote {
    font-size: 16px
}

.cbp_tmtimeline>li .cbp_tmlabel .map-checkin {
    border: 5px solid rgba(235, 235, 235, 0.2);
    -moz-box-shadow: 0px 0px 0px 1px #ebebeb;
    -webkit-box-shadow: 0px 0px 0px 1px #ebebeb;
    box-shadow: 0px 0px 0px 1px #ebebeb;
    background: #fff !important
}

.cbp_tmtimeline>li .cbp_tmlabel h2 {
    margin: 0px;
    padding: 0 0 10px 0;
    line-height: 26px;
    font-size: 16px;
    // font-weight: normal
}

.cbp_tmtimeline>li .cbp_tmlabel h2 a {
    font-size: 15px
}
.cbp_tmtimeline>li .cbp_label h2 a {
    font-size: 15px
}

.cbp_tmtimeline>li .cbp_tmlabel h2 a:hover {
    text-decoration: none
}

.cbp_tmtimeline>li .cbp_tmlabel h2 span {
    font-size: 15px
}

.cbp_tmtimeline>li .cbp_tmlabel p {
    color: #444
}
.cbp_tmtimeline>li .cbp_label p {
    color: #444
}

.cbp_tmtimeline>li .cbp_tmicon {
    width: 40px;
    height: 40px;
    speak: none;
    font-style: normal;
    // font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 1.4em;
    line-height: 40px;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    color: #fff;
    background: white;
    border-radius: 50%;
    // box-shadow: 0 0 0 5px #f5f5f6;
    border: 1px solid $aemBlue;
    text-align: center;
    left: 4%;
    top: 30px;
    margin: 0 0 0 -25px
}

@media (max-width: 767px) {
    
    .left43{
        left: 10.3% !important;
    }

    .red-tick{
        margin-left: 23px !important;
    }
    .green-tick {
        margin-left: 23px !important;
    }

    .cbp_verticalline:before {
        content: '';
        position: absolute;
        top: 30px;
        bottom: 0;
        width: 1px;
        background: #264AE4;
        left: 8%;
        margin-left: -6px;
        height: 10rem;
    }

    .cbp_dottedline:before{
        content: '';
        position: absolute;
        top: 30px;
        bottom: 0;
        width: 1px;
        // background: $aemBlue;
        left: 8%;
        margin-left: -6px;
        height: 9rem;
        border-left: 1px dashed;
        color: $aemBlue;
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
}


@media screen and (max-width: 992px) and (min-width: 768px) {
    .cbp_tmtimeline>li .cbp_tmtime {
        padding-right: 60px
    }


}

@media screen and (max-width: 65.375em) {
    .cbp_tmtimeline>li .cbp_tmtime span:last-child {
        font-size: 12px
    }

    .cbp_tmtimeline>li .cbp_tmlabel {
        margin: 0 0 15px 78px;
        background: $aemWhite;
        padding: 1.2em;
        position: relative;
        border-radius: 5px;
        width: 43rem;
    }

    .cbp_tmtimeline>li .cbp_label {
        margin: 0 0 15px 78px;
        background: $aemWhite;
        padding: 1.2em;
        position: relative;
        border-radius: 5px;
    }
}

@media screen and (max-width: 47.2em) {
    .cbp_tmtimeline:before {
        display: none
    }
    .cbp_tmtimeline>li .cbp_tmtime {
        width: 100%;
        position: relative;
        padding: 0 0 20px 0
    }
    .cbp_tmtimeline>li .cbp_tmtime span {
        text-align: left
    }
    .cbp_tmtimeline>li .cbp_tmlabel {
        margin: 0 0 30px 15%;
        padding: 1em;
        // font-weight: 400;
        font-size: 95%
    }
    .cbp_tmtimeline>li .cbp_label {
        margin: 0 0 30px 15%;
        padding: 1em 1em 5px 0;
        // font-weight: 400;
        font-size: 95%
    }
    // .cbp_tmtimeline>li .cbp_tmlabel:after {
    //     right: auto;
    //     left: 20px;
    //     border-right-color: transparent;
    //     border-bottom-color: #f5f5f6;
    //     top: -20px
    // }
    .cbp_tmtimeline>li .cbp_tmicon {
        position: relative;
        float: right;
        left: auto;
        margin: -64px 5px 0 0px
    }
    .cbp_tmtimeline>li:nth-child(odd) {
        border-right-color: transparent;
        border-bottom-color: #f5f5f6
    }

    
}

.bg-green {
    background-color: #50d38a !important;
    color: #fff;
}

.bg-blush {
    background-color: #ff758e !important;
    color: #fff;
}

.bg-orange {
    background-color: #ffc323 !important;
    color: #fff;
}

.bg-info {
    background-color: #2CA8FF !important;
}

.top20 {
    top: 20px !important;
  }

.tick-side-open{
    margin-left: 25px !important;
}

@media screen and (min-width:1367px) and (max-width:1599px){
    
    .red-tick{
        margin-left: 55px !important;
    }
    .green-tick {
        margin-left: 55px !important;
    }

    .tick-side-open{
        margin-left: 44px !important;
      }
    
}

@media screen and (min-width:1600px) and (max-width:3399px){
    
    .red-tick{
        margin-left: 63px !important;
    }
    .green-tick {
        margin-left: 63px !important;
    }
    .tick-side-open{
        margin-left: 53px !important;
      }
      
}


@media screen and (min-width:1200px) and (max-width:1266px){    
    .tick-side-open{
    margin-left: 38px !important;
  }
  
}


@media screen and (min-width:500px) and (max-width:1199px){    
    .tick-side-open{
    margin-left: 40px !important;
  }
  
}


.tick-fix{
    margin-top: 14px !important;
}

.col-gap-8{
    column-gap: 8px !important;
}

.tim-status{
font: normal normal normal 14px/22px 'G&B-BodyCopy';
letter-spacing: 0px;
color: #6F6F6F;
}

.status-check-radios{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    column-gap: 37px !important;
}

.tim-status{
    .form-check-label{
        padding-top: 2px !important;
    }
}

.timHedTrdI-width{
    width: max-content;
    margin-left: -4px;
}
