
@font-face {
    font-family: 'G&B-Headline';
    src: url('../layout/GE_Fonts/G&B-Headline.woff2') format('woff2'),
        url("../layout/GE_Fonts/G&B-Headline.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'G&B-BodyCopy';
    src: url('../layout/GE_Fonts/G&B-BodyCopy.woff2') format('woff2'),
        url("../layout/GE_Fonts/G&B-BodyCopy.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'G&B-BodyCopyHighlight';
    src: url('../layout/GE_Fonts/G&B-BodyCopyHighlight.woff2') format('woff2'),
        url("../layout/GE_Fonts/G&B-BodyCopyHighlight.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }