@import "../layout/color";

.filter-input {
  font-size: 14px;
  line-height: 1;
  min-width: 140px;
  margin-right: 12px;
}

.filter-area {
  // background-color: #ffffff;
  // margin-top: 1rem;
  // padding: 1rem;
  padding-bottom: 12px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 12px;
}

.filterDivWraper {
  gap: 12px;
  --bs-gutter-x: 0px;
  background-color: #ffffff;
  margin-top: 1rem;
  padding: 1rem;
}

.filterRight {
  button {
    height: 34px;
  }

  width: auto;
  display: inline-grid;
}

.filterLeft {
  .form-select {
    font-size: 14px;
    color: #212529;
  }

  .form-control {
    font-size: 14px;
  }
}

.userMasterModal {
  .form-label {
    margin-bottom: 0px;
    margin-top: 0.5rem;
  }
}

.modal-body {
  .form-label {
    margin-bottom: 0px;
    margin-top: 0.5rem;
  }
}