@import "../layout/color";
@import "./font.scss";
:root {font-size: 16px;}

.login-container {
  overflow: hidden;
  background-color: white;
  padding: 0px 0px;
    font-family: 'G&B-BodyCopy', sans-serif;
    // font-weight: 400;
    height: 100vh;
    color: #707070;
}
.LoginRightRadioDiv {
  display: flex;
  gap: 16px;
  .LoginRightRadioDivTitle {
    color: #707070;
    font-size: 0.75rem;
    font-family: 'G&B-BodyCopy', sans-serif;
    margin-top: 1.5rem;
    margin-bottom: -10px;
    // font-weight: 500;
  }
  .LoginRightRadioDivTitleBlue {
    color: #810055;
    font-size: 14px;
    // font-weight: 500;
  }
  .LoginRightRadioLabel { //added
    // font-weight: 600;
    .form-check{font-size: 1rem;}

.form-check-input:focus {
    border-color: #810055; box-shadow: none;}
.form-check-input:checked{background-color: #810055!important; border-color: #810055; box-shadow: none;}
  }

  .form-check-label {
    font-size: 16px;
    color: #707070;
    font-family: 'G&B-BodyCopy', sans-serif;

  }
}
.LoginRightInput {
  text-align: left;
  margin-bottom: 5px;//added
  .form-label {
    font-size: 12px;
    // font-weight: 500;
    margin-bottom: 23px;
    font-family: 'G&B-BodyCopy', sans-serif;
    color: #707070;

  }
  .inputPassword {
    position: relative;
    .input-group-text {
      padding: 0px;
      img {
        width: 36px;
      }
    }
    .inputPasswordEyeIcon {
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  .loginButton {
    width: 100%;
    background-color: $aemLogin;
    border: 1px solid $aemLogin;
  }
  .forgotButton {
    width: 100%;
  }
  .disabledButton{
    background-color: $aemGrey;
    border: 1px solid $aemGrey;
  }
}
.loginLeftImage img {
  // margin-top: 3%;
  width: 250%;
  height: 105%;
  object-fit: contain;
  overflow: hidden;
  right: -50%;
  top: 2%;
  position: absolute;
}
.loginLeftImage {
  position: relative;
  height: calc(100vh - 17px);
  margin-left: 40%;
  // padding: 5%;
}
.LoginForgotTitle {
  font-size: 18px;
  // font-weight: 600;
}
.loginImgRight1 {
  width: 15%;
}
.loginImgRight2 {
  width: 38%;
}
.forgotText {
  cursor: pointer;
  // right: 0;
  color: $aemBlue;
  font-size: 14px;
  // font-weight: 500;
  //margin-left: 250px;
  position: absolute;
  //padding-right: 1rem;
}

.forgotModalText {
  cursor: pointer;
  right: 0;
  position: absolute;
  padding-right: 3rem;
}
.loginModal {
  justify-content: flex-start;
}
.loginErrorDiv {
  background: $aemLightRed;
  color: #E1142E;
  border-radius: 5px;
  text-align: left;
  padding: 0.8rem;
  font-size: 14px;
  margin-top: -2rem;
  margin-bottom: 1rem;
  // .loginErrorDivHeader {
  //   font-weight: 600;
  // }
}
.forgotPasswordModal {
  .modal-body {
    text-align: center;
    .modalHeading {
      // font-weight: 600;
      margin: 20px 0px 10px 0px;
    }
    .modalText {
      font-size: 14px;
      padding: 0 5rem 0 5rem;
      color: $aemGrey;
    }
  }
}
.alertBoxLogin {
  position: fixed;
  z-index: 100;
  width: -webkit-fill-available;
  margin: 15px 15px 0px 15px;
}

.textright {
  text-align: right;
  // color: $aemGrey;
  color: #810055;
  font-size: 14px; //added
  // font-weight: 500;
  cursor: pointer;
  font-family: 'G&B-BodyCopy', sans-serif;
}
//=======================================================
.loginLeftImageGodrej {
  padding: 0px 6rem;
  border: 1px solid #F1F1ED;
  position: absolute;
  z-index: 9999;
  background-color: white;
  height: 3.5rem;
  margin: 0 !important;
  width: 100%;
  }
  .headerLink{
    font-family: 'G&B-BodyCopy', sans-serif;
    font-size: 0.75rem;
     color: #810055;
  }
  .LoginRightImage{
    // padding: 6rem 4rem 6rem 3rem; 
    padding: 6rem 2rem 6rem 2rem;
    font-size: 1rem;  
    font-family: 'G&B-BodyCopy', sans-serif;
    margin-top: 2rem;
    }
    
    .LoginRightImageAem h1{font-size: 3.75rem; font-family: 'G&B-Headline', sans-serif;color: #810055;margin-top: 3rem;margin-bottom: -5px;}
    .LoginRightImageAem h5{font-size: 1.25rem; font-family: 'G&B-Headline', sans-serif;color: #707070;margin-left: 4px;}
 
    .loginImage img {
      object-fit: cover; 
      background: no-repeat;
    background-position: 0 0 !important;
    background-size: cover;
    height: 100vh;
    width: 101%;
    // padding-left: 0rem;
    // clip-path: inset(0 2.8px 0 0);
  }
  .input-group{
    gap: 20px;
  }
        .form-check-input{
          border-color: #dee2e6;
        }
        
.LoginRightInput .form-control {
  height: 50px;
  // width: 326px;
  border-color: #f1f1ed;
  color: $aemGrey;
  padding: 0 15px;
  font-size: 14px;
  margin-top: -19px;
  background-color: white;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.login-form-control:focus {
  border-color: #333 !important;
  box-shadow: none;
  background-color: #ffffff;
}
.form-control:disabled, .form-control[readonly] {
  background-color: white;
  opacity: 1;
}
.form-control.placeHolderInput::placeholder{
  font-size: 14px;
  font-family: 'G&B-BodyCopy', sans-serif;
  color: #707070;
}
.w-100.rounded-pill {
  background-color: #810055;
  padding: 1rem;
  border: 0px;
  // width: 110% !important;
  box-shadow: none;
  color: #fff;
  font-size: 1rem;
  font-family: 'G&B-BodyCopyHighlight', sans-serif;
  margin-right: 20px;
}
.w-100.rounded-pill:disabled{
  background-color: #f1f1ed;
  color: #707070;
}
.w-100.rounded-pill:enabled{
  box-shadow: none;
}

.alertBoxLogin{
margin-top: 3.5rem;
height: 3rem;
text-align: center;
}
//=======================================================

@media (max-width: 767px) {
  .forgotText {
    cursor: pointer;
    // right: 0;font-size: 14px;
    color: $aemBlue;
    font-size: 14px;
    // font-weight: 500;
    //margin-left: 235px;
    position: absolute;
    //padding-right: 1rem;
  }
}
//=======================================================
 
@media (max-width: 576px) {
  .login-container {
      --bs-body-font-size: 14px;
      // --bs-body-font-weight: 400;
  }

  .LoginRightImage {padding: 3rem;}
// .LoginRightImage {padding: 3rem 2rem 3rem 1.5rem;} //added

  .loginImage img{display: none;}

  .form-check {font-size: 0.875rem;}
}

@media (max-width: 768px) {
  .LoginRightImage {padding: 3rem;}
  // .LoginRightImage {padding: 3rem 2rem 3rem 1.5rem;} //added

  .loginImage img{display: none;}

  .login-container {
      --bs-body-font-size: 16px;
      // --bs-body-font-weight: 400;
  }

  /* .form-control {font-size: 14px;} */

  .form-check {font-size: 1rem;}

  .loginLeftImageGodrej {
      padding: 0.5rem 1.5rem 1rem 1.5rem;
      border-bottom: 1px solid #F1F1ED;
  }

}
@media (max-width: 992px) {
  .LoginRightImage {padding: 4rem;}
  // .LoginRightImage {padding: 4rem 3rem 4rem 2.5rem;} //added
}

@media (max-width: 1200px) {
// .LoginRightImage{padding: 4rem 3rem;}
// .LoginRightImage {padding: 4rem 3rem 4rem 2.5rem;} //added
.LoginRightImage {padding: 6rem 1.5rem 6rem 1.5rem;} //added

}

@media (max-width: 1400px) {
  // .LoginRightImage {padding: 5rem 3.5rem 5rem 3rem;} //added
  .LoginRightImage {padding: 6rem 1.5rem 6rem 1.5rem;} //added
}

@media (max-width: 1920px) {
  // .LoginRightImage {padding: 6rem 4rem;}
  // .LoginRightImage {padding: 6rem 4rem 6rem 3rem;} //added
  .LoginRightImage {padding: 6rem 2rem 6rem 2rem;} //added
}