@import "../layout/color";

.graphRow {
  padding: 0rem 0rem 1rem 0.8rem;
  overflow: auto;
  flex-flow: row;
  .graphCol {
    
    margin-top: 1rem;
    padding: 0 0.5rem;
    width: 424px
  }
  .graphCol1 {
    margin-top: 1rem;
    padding: 0rem;
    margin-right: 5px;
    //width: 424px;
    
  }
}
.dash-card {
  border-radius: 5px !important;
  background-color: $aemWhite;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  padding: 0.6rem 1.5rem;
  border: 1px solid #f1f1ed;
}
.dash-card-graph {
  border-radius: 10px !important;
  background-color: $aemWhite;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  padding: 0.6rem 1.5rem;
}
.dash-card-graph {
  margin-right: 0.8rem;
}
.dash-card-graph1 {
  border-radius: 5px !important;
  background-color: $aemWhite;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  padding: 0.6rem 1.5rem;
  margin-top: 1rem;
  border: 1px solid #f1f1ed;
}
@media screen and (min-width: 768px) {
  .dash-card-graph {
    margin-right: 0.8rem;
  }
}
.dash-card-graph-mobile{
  margin-right: 0px;
}
.chartHeader {
  display: flex;
  justify-content: space-between;
  .chartHeaderText {
    text-align: left;
    font-size: 12px;
    line-height: 16px;
    margin-right: 10px;
    color: #333333;
  }
  .chartHeaderDate {
    justify-content: flex-end;
    .chartHeaderYear {
      font-size: 12px;
      color: #707070;
    }
    display: flex;
    gap: 5px;
    .chartDateSelect {
      padding: 0rem 2rem 0.175rem 0.65rem;
      font-size: 12px;
    }
    .chartYear {
      padding: 0rem 1rem 0.175rem 0rem;
      font-size: 12px;
    }
  }
}
.pieChartRow {
  margin: 0px;
  .pieChartContainer {
    padding-top: 0.8rem;
    text-align: -webkit-left;
    border: 1px solid $dropBorder;
    border-radius: 5px;
    .pieChartWrapper {
      width: 100%;
      height:80%;
    }
  }
  .pieTableContainer {
    padding-top: 0.8rem;
    text-align: -webkit-center;
    .table-cell{
      font-size: 12px;
      span{
        font-size: 12px;
        // color: #707070;
      }
    }
    .table-body{
      .table-row{
        background: #fff !important;
      }
    }
  }
}
.graphModalBody {
  text-align: right;
  .graphCloseIcon {
    cursor: pointer;
  }
}

// all solution table css
.tableHeaderInput {
  background: transparent;
}
.owl-dots{
  .active{
    span{
      width: 20px !important;
      background: $aemBlue !important;
    }
  }
}

.pieModalWidth.modal.show .modal-dialog{
  max-width: 43rem !important;
}