@import "./styles/index.scss";
@import "./styles/pages/font.scss";
.app-layout {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  max-width: 100vw !important;
  display: flex;
  flex-direction: row;
}

.side-bar {
  width: 20rem;
  min-height: 100vh;
  flex: 0 0 auto;
  background-color: #ffffff;
  transition: all 0.4s ease;
}

.app-container {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  width: 100%;
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: #810055  !important;
  box-shadow: none;
}

.main-container {
  overflow: auto;
  color: #707070;
  min-height: calc(100vh - 3.5rem);
  background-color: #ffffff;
  max-width: 100%;
}

.header {
  height: 3.5rem;
  color: #333333;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  background-color: white;
  border-bottom: 1px solid #f1f1ed;
}

.mobile-header {
  display: none;
  background: $aemWhite;
  height: 40px;
  padding: 8px;
  // font-weight: 600;
  font-size: 18px;
  text-align: center;
  box-shadow: inset 0px 1px 5px -3px;
}

.menu-name-header {
  // font-weight: 600;
  font-size: 18px;
  margin: 0.5rem;
  color: #810055;
  font-family: 'G&B-Headline';
}

.menu-name {
  width: 100%;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  padding-left: 7px;
}

.user-section {
  margin-right: 1rem;
  display: flex;
  align-items: flex-end;
}

.user-info {
  display: flex;
  flex-direction: column;
  margin-right: 0.75rem;
  justify-content: right;
  align-items: flex-end;
  color: #707070;
  padding: 0.25rem;
}

.user-name {
  font-size: 14px;
  // font-weight: 500;
  font-family:'G&B-Headline';
  letter-spacing: 0px;
  color: #333333;
  //line-height: 1rem;
}

.user-email {
  font-size: 10px;
  // line-height: 13px;
  font-family:'G&B-Headline';
  letter-spacing: 0px;
  color: #333333;
}

.sidebar-head {
  height: 3.5rem;
  // box-shadow: 3px 3px 6px #f2f2f2;
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // box-shadow: -3px 1px 3px 3px #f2f2f2;
}

.sidebar-head-2 {
  // height: 3.5rem;
  // box-shadow: 3px 3px 6px #f2f2f2;
  padding: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: -3px 1px 3px 3px #fff;
}

.menu {
  // font-weight: 600;
  color: #810055;
  margin-left: 1.5rem;
  font-family: 'G&B-Headline';
}

.sidebar-body {
  padding-top: 1rem;
  height: calc(100vh - 3.5rem);
}

.nav-list {
  list-style-type: none;
  margin-top: 0.25rem;
  padding-left: 0;
}

// .nav-list .nav-item {
//   margin-top: 0.25rem;
// }

.nav-list-item {
  padding: 0.5rem 1rem;
  height: 3rem;
  display: flex;
  align-items: center;
  color: #707070;
  text-decoration: none;
}

.nav-list-item.active1,
.nav-list-item:hover,
.nav-list-item.active1 .menu-icon,
.nav-list-item:hover .menu-icon {
  background-color: #f9f2f6 !important;
  color: #810055 !important;
}

.nav-list-item.inactive {
  background-color: #ffffff;
}

.nav-list-item .menu-icon {
  padding: 0.25rem;
  color: #264ae4;
}

.nav-sub-menu {
  list-style-type: none;
  padding-left: 3.5rem;
}

.sub-menu-item {
  text-decoration: none;
  color: #707070;
  font-size: 14px;
}

.nav-sub-menu .nav-item {
  margin-top: 0.5rem;
}

.side-bar.closed,
.side-bar.closed .sidebar-head,
.side-bar.closed .sidebar-body {
  width: 3.5rem;
}

.side-bar.closed .sidebar-head .head-logo,
.side-bar.closed .menu-name,
.head-toggle {
  display: none;
}

.side-bar.closed .menu {
  opacity: 0;
  margin: 0;
}

.side-bar.closed .nav-list-item {
  padding: 0.5rem 0.65rem;
}

.side-bar.closed .head-toggle,
.head-logo {
  display: block;
  cursor: pointer;
}

.aem-logo {
  padding-left: 150px;
}

.user-action {
  cursor: pointer;
  margin-top: -1rem;
}

.headerMenu {
  position: absolute;
  height: 1.3rem;
  width: 100px;
  background-color: #ffffff;
  top: 2.5rem;
  right: 0;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  box-shadow: 3px 3px 6px #fff;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  margin-top: -0.62rem;
}

.headerMenu-item {
  display: flex;
  text-decoration: none;
  color: #707070;
  font-size: 12px;
  // font-weight: 500;
  align-items: center;
  margin-top: -0.3rem;
  border: 1px solid #f1f1ed;
  height: 24px;
}

.headerMenu.show {
  opacity: 1;
  visibility: visible;
}

.user-logo {
  height: 2.5rem;
  width: 2.5rem;
  background-color: #f1f1ed;
  color: #810055;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-logo.big {
  height: 3.5rem;
  width: 3.5rem;
  font-size: 24px;
  // font-weight: 500;
}

.dashboardHeader {
  // font-weight: 600;
  font-size: 20px;
  line-height: 1.5rem;
  color: #333333;
}

.dashboardHeaderDesc {
  font-size: 12px;
  // font-weight: 500;
  color: #707070;
}

.user-greet {
  display: flex;
  margin-left: 1.5rem;
  flex-direction: column;
  justify-content: center;
}

.pending-recs {
  margin-top: 0.75rem;
}

.pending-recs .heading {
  color: #333333;
  font-size: 16px;
  line-height: 20px;
  font-family:'G&B-Headline';
}

.dash-card-recs {
  margin-top: 0px;
}

.form-circle {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #707070;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #707070;
}

.form-box {
  // font-weight: 500;
  width: 130px;
  font-size: 14px;
  color: #707070;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.form-box.active {
  color: #810055;
}

.form-box.complete {
  color: #707070;
}

.form-box.active .form-circle {
  background-color: #810055;
  border: 1.5px solid #810055;
  color: #ffffff;
}

.form-box.complete .form-circle {
  background-color: #f9f2f6;
  border: 1px solid #810055;
  color: #810055;
}

.ag-header .ag-header-cell-text {
  color: #ffffff;
  // font-weight: 500;
  font-size: 14px;
  font-family: 'G&B-Headline';
}

.ag-cell {
  color: #707070;
  font-size: 14px;
  // font-weight: 500;
}

.ag-paging-panel {
  border: none !important;
  height: 25px !important;
  background-color: #ffffff;
}

.soln-tabs {
  min-height: 90px;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.enq-no {
  background-color: #f1f1ed;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0.25rem 1.5rem;
}

/* #NewSolution .tab-pane {
  background-color: #FFF;
} */

.Comp-App-Tabs {
  font-size: 13px;
  // font-weight: 600;
  color: #ffffff;
  height: 34px;
}

.app-tab {
  display: grid;
  place-items: center;
  /* background-color: #888787; */
  border-radius: 4px 5px 0px 0px;
  cursor: pointer;
  background: transparent linear-gradient(180deg, #888787 0%, #5f5e5e 100%) 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 2px #33333329;
}

.app-tab.active,
.Comp-App-Tabs.active {
  background: transparent linear-gradient(180deg, #4b4b4b 0%, #262626 100%) 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 4px #2c2c2c29;
}

#CompressorAppDetails {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.test {
  width: 80px;
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  padding: 4px;
  width: 64px;
  border-right: 1px solid #f1f1ed;
  display: grid;
  place-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.test:hover {
  color: #fff;
}

.test.active {
  background: #230001 0% 0% no-repeat padding-box;
}

.my-label {
  font-size: 12px;
  line-height: 1rem;
  color: #fff;
  // font-weight: 500;
  margin-bottom: 0 !important;
}

.app-add-btn {
  height: 2rem;
  // width: 5.5rem;
  background: transparent linear-gradient(180deg, #49474b 0%, #222029 100%) 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  // font-weight: 500;
  cursor: pointer;
}

.app-add-btn.active {
  background: transparent linear-gradient(180deg, #810055 0%, #810055 100%) 0% 0% no-repeat padding-box;
}

.activeComp {
  border-radius: 4px;
}

.application-form {
  // height: 200px;
  width: 400px;
  margin-top: 0.375rem;
  padding-right: 1.5rem;
}

.margin-left-35 {
  margin-left: 3.5rem !important;
}

.save-btn {
  margin: 5px 0;
  height: 38px;
  width: 6.5rem;
  background-color: #810055;
  border-radius: 20px !important;
  border: none;
  color: #fff;
  // font-weight: 500;
  font-size: 15px;
}

.submit-btn {
  margin: 5px 0;
  height: 38px;
  width: 5.5rem;
  background-color: #810055;
  border-radius: 4px;
  border: 1px;
  color: #fff;
  // font-weight: 500;
  font-size: 15px;
}

.alertBox {
  width: 100%;
  font-size: 14px;
}

.alertBox-rm {
  width: 100%;
  font-size: 14px;
  margin-top: 7rem;
}

.list-bullet {
  height: 0.5rem;
  width: 0.5rem;
  background-color: #810055;
  // background-color: rgba(38, 74, 228, 1);
  border-radius: 50%;
  display: grid;
  place-items: center;
  //margin-left: 0.3rem;
}

//downlaod reactflow

#download-image .react-flow {
  background: #1a365d;
}

#download-image .react-flow__node {
  width: 50px;
  height: 50px;
  color: white;
  // font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: white;
}

#download-image .react-flow__node-custom {
  font-size: 12px;
  background: #fff;
  border: 1px solid #f1f1ed;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}

#download-image .react-flow__node-custom .react-flow__handle-right {
  transform: none;
}

#download-image.wrapper {
  position: relative;
  height: 100%;
}

//

.list-bullet.active,
.application-form .row:focus-within .list-bullet {
  box-shadow: 0px 0px 0px 4px rgba(129, 0, 85, 0.3),
    0px 0px 0px 8px rgba(129, 0, 85, 0.31);
}

.dot {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  color: rgba(38, 74, 228, 1);
  margin-right: 0.5rem;
}

.dot.active {
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.3);
}

.dot.prev {
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 1);
}

.my-drop-btn {
  border-radius: 4px;
  border: none;
  min-height: 2rem;
  width: 9rem;
  text-align: left;
  padding: 5px 0.5rem;
  /* display: flex;
  justify-content: space-between; */
  align-items: center;
  color: #fff;
  text-overflow: ellipsis;
  font-size: 14px;
}

.my-drop-btn:focus {
  outline: none;
  box-shadow: none;
}

.my-drop-btn.drop-from {
  background: transparent linear-gradient(180deg, #49474b 0%, #222029 100%) 0% 0% no-repeat padding-box;
}

.my-drop-btn.drop-to {
  background: transparent linear-gradient(359deg, #49474b 0%, #222029 100%) 0% 0% no-repeat padding-box;
}

.my-drop-menu {
  background: transparent linear-gradient(180deg, #7f86a0 0%, #6f7381 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000080;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  width: 12.5rem;
  font-size: 14px;
  // font-weight: 400;
  padding: 0.5rem;
}

/* Add here */

.soln-tabs::after {
  width: 100%;
  height: 1px;
  content: "";
  position: absolute;
  top: 24px;
  left: 0;
  background-color: #707070;
  z-index: -1;
}

.application-form .row::before {
  content: "";
  width: 4px;
  height: 45px;
  background-color: #fff;
  position: relative;
  left: 10%;
  top: 49px;
  // left: 33px;
  // top: 39px;
}

.lastRow::before {
  display: none;
}

.lastRow .bulletStyle {
  margin-left: 16px !important;
  margin-top: 26px;
}

.application-form .row:last-child::before {
  background: none;
}

.footer {
  height: 4rem;
  border-top: 1px solid #f1f1ed;
  padding: 0.5rem 1rem;
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  width: 95%;
  margin-left: 1rem;
}

#NetworkDets ::placeholder {
  font-size: 14px;
  color: #fff;
}

#AddCompressor .nav .active {
  border-bottom: 4px solid #810055;
  // font-weight: 600;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

.modal-90w .table-row {
  padding: 0;
}

.modal-90w input {
  width: 80px;
  background-color: #fff;
  border: 1px solid #f1f1ed;
  border-radius: 4px;
  border-image: initial;
  padding-right: 35px;
}

.modal-90w .table-cell {
  min-width: 90px;
  // align-items: left;
}

#NetworkDets .form-check-label {
  color: #fff;
  font-size: 14px;
  /* font-weight: 500; */
}

#NetworkDets .form-check-input:checked {
  background-color: #33c037;
  border-color: #33c037;
}

// #customer-details input:not(:focus):placeholder-shown:invalid {
//   border-color: #ff0000;
// }

// #customer-details input[type="text"]:not(:focus):not(:placeholder-shown):valid {
//   border-color: #0dfd55;
// }

.filter-action {
  font-size: 13px;
  // font-weight: 600;
  color: #707070 !important;
}

.filter-area :is(.form-select, .form-control),
.filter-area .form-control::placeholder {
  color: #707070 !important;
}

select.form-select1 {
  color: #fff;
  background-color: #7f86a0;
  //width: fit-content;
  margin: auto;
}

select.unitBox {
  min-width: 5rem;
  //height: 2.4rem;
}

.ag-theme-material .ag-has-focus .ag-cell-focus {
  border: none !important;
}

.table-responsive {
  max-height: 20px;
  overflow-y: scroll;
}

.LogbookForm {
  margin: 0;
  box-sizing: border-box;
  text-align: left;
  // font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.FormHeader {
  text-align: center;
}

.FormButtons {
  display: flex;
  justify-content: center;
}

.FeedbackLabel {
  // font-weight: 700;
  font-size: 25;
}

#fourOFour {
  display: flex;
  justify-content: center;
  // width: calc(100% - 360px);
  height: 100%;
  text-align: center;
}

.fof {
  display: table-cell;
  vertical-align: middle;
  transform: translateY(50%);
}

.tableWrapperCompressorModal {
  .text-danger {
    width: 100%;
  }

  .tableButtons {
    position: absolute;
    top: 7px;
  }

  .table-row {
    height: 100%;
    padding-top: 14px;
    padding-bottom: 10px;
    width: fit-content;
    margin-top: 8px;
  }

  .table-cell {
    align-items: flex-start;
    display: flex;
    min-width: 120px;
    max-width: 120px;
    flex-direction: column;
    align-self: center;
  }

  .dashboardTable {
    width: 40%;
    flex-flow: column;
  }

  .dashboardTable1 {
    width: 50%;
  }

  .tableCellPopup {
    // flex-direction: inherit;
    min-width: 120px;
    max-width: 120px;
  }

  input {
    //width: -webkit-fill-available;
    width: 100%;
    border: 1px solid #f1f1ed;
    color:#707070;
  }

  .headerSelect {
    border: none;
    background: #fff;
    border-radius: 5px;
    width: 91px;
    color: #333333;
  }

  .bodySelect {
    height: 1.5rem;
    border: 1px solid #f1f1ed;
    background: #fff;
    border-radius: 5px;
    width: -webkit-fill-available;
    color: #707070;
  }

  select {
    //width: -webkit-fill-available;
    width: 100%;
  }

  .tableHeaderLabel {
    font-size: 10px;
    margin-top: 0px !important;
  }

  .cellActionCustom {
    position: static;
    opacity: 0;
    visibility: hidden;
  }
}

.tableWrapperCustomPopupModal {
  .table-row {
    height: 100%;
    padding-top: 14px;
    padding-bottom: 10px;
  }

  .table-cell {
    align-items: flex-start;
    display: flex;
    min-width: 120px;
    max-width: 120px;
    flex-direction: column;
    align-self: baseline;
  }

  .tableCellPopup {
    min-width: 120px;
    max-width: 120px;
  }

  .headerSelect {
    border: none;
    background: #fff;
    border-radius: 5px;
    color: #333;
  }

  .bodySelect {
    height: 1.5rem;
    border: 1px solid #f1f1ed;
    background: #fff;
    border-radius: 5px;
    width: -webkit-fill-available;
    color: #707070;
  }

  .tableHeaderLabel {
    font-size: 10px;
  }

  .cellActionCustom {
    position: static;
    opacity: 0;
    visibility: hidden;
  }
}

// .tableWrapperCompressorModal {
//   .cellActionCustom {
//     position: static;
//     opacity: 1;
//   visibility: visible;
//   }
// }

.compTextWidth {
  width: 100% !important;
}

.placeHolderInput::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #707070;
  font-size: 14px;
}

.placeHolderInput:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #707070;
  font-size: 15px;
  opacity: 1;
}

.placeHolderInput::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #707070;
  font-size: 14px;
  opacity: 1;
}

.placeHolderInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #707070;
  font-size: 14px;
}

.placeHolderInput1::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff;
  font-size: 14px;
}

.placeHolderInput1:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  font-size: 15px;
  opacity: 1;
}

.placeHolderInput1::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  font-size: 14px;
  opacity: 1;
}

.placeHolderInput1:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
  font-size: 14px;
}

.inputTextWhite {
  color: #fff;
}

.inputText {
  font-size: 14px;
  padding-right: 35px;
  color: #707070;
}

.inputText-cm {
  font-size: 14px;
  padding-right: 35px;
  margin-top: 0.7rem;
}

.executiveSummary {
  background: #fff;
}

.roiTableWrapper {
  background: #fff;

  .roiTable {
    .table-head {
      .table-row {
        background: #fff;
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }

    .table-body-custom {
      .table-row {
        width: fit-content;
      }
    }

    .table-cell {
      min-width: 130px;
      max-width: 130px;
      padding-right: 0.5rem;
    }

    .table-cell1 {
      min-width: 165px;
      max-width: 165px;
      padding-right: 0.5rem;
    }

    input {
      background: #fff;
      border: 1px solid #f1f1ed;
      border-radius: 5px;
      width: 100%;
    }
  }

  .table-bordered {
    font-size: small;
    color: #707070;
    // font-weight: 100;
  }

  .blueBottomRoi {
    // font-weight: lighter;
    border-radius: 5px;
    font-size: small;
    display: flex;

    //justify-content: center;

    span {
      color: "#810055";

      width: 100%;
    }

    .roiBlueTabLeft {
      display: flex;
      gap: 40px;
      border-right: 1px solid white;
      padding-right: 40px;
      height: 30px;
      font-size: small;
    }

    .roiBlueTabRight {
      display: flex;
      gap: 40px;
      border-right: 0 solid white;
      padding-right: 40px;
      height: 30px;
      font-size: small;
    }

    // span {
    //   font-weight: 800;
    // }
  }

  .greenBottomRoi {
    height: 40px;
    border-radius: 5px;
    background: $aemGreenLight;
    display: flex;
    gap: 40px;
    justify-content: center;

    .roiGreenTabLeft {
      display: flex;
      gap: 40px;
      border-right: 1px solid white;
      padding-right: 40px;
      height: 30px;
      margin-top: 5px;
      padding-top: 4px;
    }

    .roiGreenTabRight {
      display: flex;
      gap: 40px;
      height: 30px;
      margin-top: 5px;
      padding-top: 4px;
    }

    // span {
    //   font-weight: 800;
    // }
  }

  .whiteBottomRoi {
    //height: 60px;
    border-radius: 5px;
    background: white;
    display: flex;
    gap: 40px;
    justify-content: center;

    .roiGreenTabLeft {
      display: flex;
      gap: 40px;
      border-right: 1px solid white;
      padding-right: 40px;
      height: 30px;
      margin-top: 5px;
      padding-top: 4px;
    }

    .roiGreenTabRight {
      display: flex;
      gap: 40px;
      height: 30px;
      margin-top: 5px;
      padding-top: 4px;
    }

    // span {
    //   font-weight: 800;
    // }
  }

  .whiteBottomRoiEnd {
    //height: 60px;
    border-radius: 5px;
    background: white;
    display: flex;
    gap: 40px;
    justify-content: center;

    .roiGreenTabLeft {
      display: flex;
      gap: 40px;
      border-right: 1px solid white;
      padding-right: 40px;
      height: 30px;
      margin-top: 5px;
      padding-top: 4px;
    }

    .roiGreenTabRight {
      display: flex;
      gap: 40px;
      height: 30px;
      margin-top: 5px;
      padding-top: 4px;
    }

    // span {
    //   font-weight: 800;
    // }
  }
}

.energyPercent {
  //height: 25px;
  background: #f9f2f6 0% 0% no-repeat padding-box;
  border: 1px solid #810055;
  border-radius: 16px;
  opacity: 1;
  text-align: center;
  margin-top: 10px;
  display: flex;
  padding: 2px;
  //gap: 100px;
  justify-content: space-evenly;
  color:#810055;
}

.energyLabel {
  //height: 16px;
  font: normal normal medium 14px/16px 'G&B-BodyCopy';
  letter-spacing: 0px;
  color: #810055;
  opacity: 1;
}

.outerTabDiv {
  margin-top: 0.5rem;
  border-radius: 5px 5px 5px 5px;

  .mobileTableRow {
    width: 100%;
  }

  .table-row {
    min-height: 48px;
    background-color: #ffffff;
    margin-top: 0px;
    // font-weight: 500;
    transition: all 300ms;
    border-radius: 0px 0px 0px 0px;
  }

  .table-row1 {
    min-height: 48px;
    background-color: #fff;
    margin-top: 0px;
    // font-weight: 500;
    transition: all 300ms;
    border-width: 5px 5px 0px 0px;
    border-color: #f1f1ed;
    padding: 1px 15px 1px 1px;
  }

  .table-row2 {
    min-height: 48px;
    background-color: #fff;
    margin-top: 0px;
    // font-weight: 500;
    transition: all 300ms;
    border-width: 5px 5px 0px 0px;
    border-color: #f1f1ed;
    position: relative;
    display: block;
  }

  .table-row3 {
    min-height: 48px;
    background-color: #fff;
    margin-top: 0px;
    margin-bottom: 10px;
    // font-weight: 500;
    transition: all 300ms;
    border-width: 5px 5px 0px 0px;
    border-color: #f1f1ed;
    padding: 1px 15px 1px 1px;
  }

  .statusHighlightGreen {
    border-radius: 5px 5px 0px 0px;
    height: 10px;
    background: $aemGreen;
  }

  .statusHighlightGrey {
    border-radius: 5px 5px 0px 0px;
    height: 10px;
    background: #707070;
  }

  .statusHighlight {
    border-radius: 5px 5px 0px 0px;
    height: 10px;
    background: $aemWhite;
  }

  .showButton {
    //background: white;
    height: 22px;
    border-radius: 0px 0px 5px 5px;
    //position: inherit;
  }

  .showButton1 {
    background-color: #fff;
    height: 22px;
    border-radius: 0px 0px 5px 5px;
    //position: inherit;
  }

  .editControlCustom {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    margin-bottom: 5px;
  }
}

#filterDisplay {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.blurrOverlay {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

@include device-sm {
  .greenBottomRoi {
    height: auto !important;
    flex-direction: column !important;
    gap: 0px !important;
    padding-left: 10px !important;
  }

  .whiteBottomRoi {
    height: auto !important;
    //flex-direction: column !important;
    gap: 0px !important;
    padding-left: 10px !important;
  }

  .roiGreenTabLeft {
    border-right: none !important;
  }

  .text-sm-center {
    text-align: center !important;
    text-align: -webkit-center !important;
  }

  .form-circle {
    height: 2.2rem;
    width: 2.2rem;
  }

  .form-box {
    font-size: 10px;
  }

  .soln-tabs::after {
    top: 19px;
  }

  .mobile-header {
    display: block;
  }

  .menu-name-header {
    display: none;
  }

  .tab-content {
    label {
      font-size: 14px;
    }

    .req-input {
      font-size: 9px;
    }

    .form-label {
      font-size: 14px;
    }

    .readonly-fields {
      font-size: 12px;
    }

    .inputText {
      font-size: 12px;
    }

    .radioContainer {
      label {
        font-size: 12px !important;
      }
    }

    .dropTextContainer {
      font-size: 12px;
    }

    input {
      font-size: 12px;
    }
  }

  .roiTable {
    .table-cell {
      padding-right: 30px !important;
      padding-bottom: 2px;
    }

    .table-body {
      width: inherit;

      .bluetableheading-row {
        background-color: #f9f2f6;
        justify-content: space-between;
        color: #707070 !important;
        display: flex;
        border: 1px solid #f1f1ed;
        border-radius: 10px 10px 0 0;
        padding: 0.5rem;
      }

      .bluetableheading {
        // background: #F5F5F5;
        padding-top: 7px;
        padding-bottom: 4px;
        color: #707070 !important;
      }
    }
  }

  .side-bar.closed,
  .side-bar.closed .sidebar-head,
  .side-bar.closed .sidebar-body {
    width: 0rem;
  }

  .sidebar-head {
    display: none;
  }

  .side-bar {
    width: 18rem;
  }
}

.remarkLabel {
  text-align: left;
  font: normal normal 16px/24px 'G&B-BodyCopy';
  letter-spacing: 0px;
  color: #810055;
  opacity: 1;
}

.accordion-header {
  // background: #cbd4f0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.accordion-button {
  border-radius: 8px;
  background: #fff 0% 0% no-repeat padding-box;
}

.profile-initials {
  width: 40px;
  height: 40px;
  // speak: none;
  font-style: normal;
  // font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 1.4em;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: #fff;
  background: white;
  border-radius: 50%;
  border: 1px solid #f1f1ed;
  text-align: center;
  left: 4%;
  top: 30px;
  // margin: 0 0 0 -25px;
  margin: 0 0 0 -40px;
  font-size: 15px;
  color: $aemBlue;
  text-align: center;
}

.green-tick {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #33c037;
  font-size: 7px;
  color: #fff;
  text-align: center;
  line-height: 16px;
  margin: 20px 0;
  position: absolute;
  margin-left: 34px;
  margin-top: 27px;
}

.red-tick {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #e1142e;
  font-size: 7px;
  color: #fff;
  text-align: center;
  line-height: 16px;
  margin: 20px 0;
  position: absolute;
  margin-left: 48px;
  margin-top: 24px;
}

.remark_text {
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #f1f1ed;
  border-radius: 8px;
  opacity: 1;
  margin-left: 11px;
}

.remark_length {
  text-align: end;
  font: normal normal normal 15px/20px Poppins;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.text-color {
  color: #707070;
}

.left-margin-none {
  margin-left: 0;
}

.blue-arrow {
  //float: right;
  padding-top: 2%;
  margin-left: 5%;
  margin-bottom: 1%;
}

.blue-arrow.downarrow {
  float: right;
  margin: -2% 0 0 3%;
}

.blue-arrow.uparrow {
  margin: -2% 0 0 3%;
}

.col-md-8.trackreportheading {
  margin: 0% 0 0 0;
  padding: 0 0 0 0;
}

.show-block {
  display: block;
}

@media (min-width: 1020px) {
  .place-center {
    .application-form.d-block {
      .g-0.mt-1.row {
        .col {
          .placeHolderInput1.form-control2.inputTextWhite.form-control {
            width: 98% !important;
          }
        }
      }
    }
  }

  // .m-0.list-unstyled {

  //   .place-center {

  //     .application-form.d-block {

  //       .g-0.mt-1.row {

  //         .place-center.col-lg-2.col-md-2.col-sm-2.col-2 {

  //           .list-bullet {
  //             // margin-left: 10%;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  .bulletStyle {
    margin-left: 7px;
    margin-top: 18px;
    display: block;
  }

  .tableWrapperCustomPopupModal {
    .headerSelect {
      border: none;
      background: #fff;
      border-radius: 5px;
      width: 180px;
      color: #333;
    }
  }
}

.centertext {
  text-align: center;
  font-size: 80%;
  padding: 0.2rem;
}

.centertext1 {
  text-align: center;
  font-size: 80%;
  padding: 0.2rem;
}

.labelStyle {
  font-size: 1rem;
  color: #333;
  // font-weight: 600;
  margin-top: 2rem;
}

.locationStyle {
  font-size: 1rem;
  color: #333;
  // font-weight: 600;
  padding-left: 10px;
}

.paneHeight {
  // height: 60vh;
  min-height: 450px;
  overflow-y: auto;
  margin-bottom: 5rem;
}

.modalIndex {
  z-index: 0;
}

//added by sakshi for mobile layout
@media (max-width: 767px) {

  // Login Page starts
  // .form-check {
  //   padding-left: 1rem;;
  // }
  // Login Page ends
  .hamburger {
    width: 48px;
    height: 48px;
  }

  .scroll-content {
    position: relative;
    width: 96%;
    margin: auto;
  }

  .table-container {
    overflow-y: auto;
    overflow-x: hidden;
  }

  //Master pages starts
  .req-input {
    margin-left: 10px;
  }

  .mobileTableModal {
    .mobileTableRow {
      .table-cell {
        padding-bottom: 0.5rem;

        span {
          margin-top: 0;
          width: 45%;
          padding-right: 1rem;
          // font-weight: 800;
          white-space: normal;
        }

        .align-text,
        .align-text2 {
          width: 50%;
          word-break: break-word;
          padding-right: 5px;
        }

        .align-text1 {
          width: 50%;
        }
      }
    }

    .blueBottomRoi {
      // font-weight: lighter;
      border-radius: 5px;
      font-size: small;
      display: flex;
      background-color: #f9f2f6;

      //justify-content: center;

      span {
        color: #707070 !important;
        padding: 7px;
        width: 55%;
        background-color: #f9f2f6;
      }
       span .p-2{
         background-color: #f9f2f6;
       }
      .roiBlueTabLeft {
        display: flex;
        gap: 40px;
        border-right: 1px solid white;
        padding-right: 40px;
        height: 30px;
        font-size: small;
      }

      .roiBlueTabRight {
        display: flex;
        gap: 40px;
        border-right: 0 solid white;
        padding-right: 40px;
        height: 30px;
        font-size: small;
      }
    }
  }

  .tableStyle tr td {
    background-color: #f9f2f6;
  }

  .outerTabDiv {
    .table-row {
      padding-top: 1.95rem;
    }

    .table-row1 {
      padding: 10px 5px;
    }
  }

  .pagintion-bar {
    padding-top: 1rem;
    font-size: 1rem;
  }

  .main-container {
    .place-center {
      background-color: #fff;
    }
  }

  .modal-body {

    // .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    //   margin-top: 1rem;
    // }
    .form-label {
      margin-bottom: 0.2rem;
      margin-top: 1.5rem;
    }
  }

  // .form-control::-webkit-input-placeholder {
  //   color: #000;
  // }

  .modal-title {
    // font-weight: 600;
    font-size: 1rem;
    color: #333333 !important;
  }

  .modal-header {
    border-bottom: 1px solid #707070;
  }

  .modal-footer {
    border-top: 1px solid #707070;
  }

  .roleStyle {
    overflow-y: auto;
    padding: 0rem 0.2rem;
    overflow-x: hidden;

    hr {
      display: none;
    }

    .container {
      padding: 0;
    }

    ul {
      li {
        margin: 5px 0;
      }
    }
  }

  .btn-close {
    opacity: 1;
    width: 0.4em;
    height: 0.4em;
  }

  .btn-add {
    margin-right: 1rem;
  }

  // .mobile-header {
  //   margin-top: 0.5rem;
  //   // box-shadow: 0px 1px 2px #222;
  // }

  .form-control,
  .form-select-ba,
  .form-select {
    border: 1px solid #f1f1ed;
    // background-color: #F8F8F8;
    // font-weight: 600;
    font-size: 0.875rem;
  }

  .form-select-ucm {
    padding: 0.2rem 1.2rem 0.2rem 0.3rem;
    font-size: 0.75rem;
    background-position: right 0rem center !important;
    width: 6rem;
  }

  .showFull {
    max-height: 178px;
  }

  .roiTable {
    .showFull {
      max-height: 162px;
      border: 1px solid #f1f1ed
    }
  }

  .mobileTableModal {
    .showButton {
      background-color: #fff;
      height: 40px;
      padding-top: 10px;
    }

    .showButton1 {
      height: 40px;
      padding-bottom: 10px;
    }

    .showButtonBlueBG {
      background-color: #fff;
    }
  }

  .outerTabDiv:first-child {
    margin-top: 0;
  }

  .energyPercent {
    //height: 3rem;
    background: #f9f2f6 0% 0% no-repeat padding-box;
    border: 1px solid #810055;
    border-radius: 16px;
    vertical-align: middle;
    opacity: 1;
    text-align: center;
    margin-top: 10px;
    display: flex;
    padding: 2px;
    justify-content: space-evenly;
    color: #810055;
  }

  .form-check-label {
    color: #707070;
    // font-weight: 600;
    font-size: 0.875rem;
  }

  .profile-initials {
    width: 40px;
    height: 40px;
    // speak: none;
    font-style: normal;
    // font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 1.4em;
    line-height: 40px;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    color: #fff;
    background: white;
    border-radius: 50%;
    border: 1px solid #f1f1ed;
    text-align: center;
    left: 8%;
    top: 30px !important;
    margin: 0 0 0 -25px;
    font-size: 15px;
    color: $aemBlue;
    text-align: center;
  }

  .tick-fix {
    margin-top: 27px !important;
  }

  .global-search-container {
    margin: 0rem 0 1rem 0;
  }

  .form-switch-input {
    .form-check-label {
      color: #707070 !important;
      // font-weight: 600;
      font-size: 0.875rem;
    }

    .rightAlign {
      position: relative;
      right: 5rem;
    }
  }

  .buttonMargin {
    position: absolute;
    // left: 14rem;
    right: 1.8rem;
  }

  .currencyBG {
    background-color: #fff;

    .scroll-content {
      width: 100%;
    }

    .borderBottom {
      border-bottom: 1px solid #707070;
      padding-bottom: 2rem;
    }
  }

  //Master pages ends
  //  Report page starts
  .reportStyle {
    .table-row {
      padding-top: 1rem;
    }

    .showFull {
      max-height: 174px;
    }
  }

  //  Report page ends
  //Solution page starts
  .mobile-btn-5 button {
    width: 100%;
  }

  .allSolnStyle {
    .showFull {
      max-height: 190px;
    }
  }

  .newSolution {
    .scroll-content {
      width: 100%;
    }
  }

  .soln-tabs {
    label {
      padding-top: 5px;
    }
  }

  .enq-no {
    justify-content: left;
    padding: 0.5rem 1rem;
  }

  .dropOuterCircle {
    margin: 8px 10px 8px 10px;
  }

  .dropTextContainer {
    // display: block;
    margin-left: 0px;

    .dropText {
      display: inline-block;
    }

    .dropTextBlue {
      padding-left: 5px;
    }
  }

  select.unitBox {
    min-width: 6rem;
    font-size: 12px;
  }

  .tab-content {
    .form-label {
      margin-top: 0;
    }
  }

  .filter {
    .btn-text {
      font-size: 14px;

    }
  }

  .form-evaluation {
    .form-label {
      margin-top: 1rem;
    }
  }

  .mobileCompressorModal {
    .tab-content {
      height: 90vh !important;
    }
  }

  .modal.show {
    .modal-90w {
      width: 96%;
      margin-top: 2rem;
    }
  }

  .react-flow__container {
    left: -58px !important;
  }

  .place-end {
    justify-content: center;
  }

  .centertext1 {
    span {
      font-size: 10px;
      padding-top: 3px;
    }
  }

  .bulletStyle {
    margin-left: 9px !important;
    margin-top: 26px;
    display: block;
  }

  .application-form .row::before {
    left: 10%;
    top: 49px;
  }

  .lastRow::before {
    display: none;
  }

  .lastRow .bulletStyle {
    margin-left: 13px !important;
    margin-top: 29px;
  }

  #ApplicationDets .trans-all-man-fields {
    margin-left: 26px;
  }

  #CompressorAppDetails {
    height: 60vh;
  }

  .informationLabel {
    height: 3rem !important;
    position: relative;
    // img{
    //   padding-top: 0.5rem;
    // }
  }

  .informationSpan {
    position: absolute;
    padding-top: 0.57rem;
    padding-right: 10px;
  }

  .newEnquiry {
    .scroll-content {
      width: 100%;
    }
  }

  .footer {
    position: fixed;
    width: 100%;
    bottom: -1px;
    overflow: hidden;
    background: #fff;
    margin-left: 0;
    height: 3rem;
  }

  .tableWrapperCompressorModal .headerSelect {
    width: 150px;
  }
  .tableWrapperCompressorModal .headerSelect:focus {
    border-color: none !important;
  }

  .roiTableWrapper {
    .whiteBottomRoi {
      //height: 60px;
      border-radius: 5px;
      background: #fff;
      display: flex;
      gap: 40px;
      justify-content: space-evenly;

      .roiGreenTabLeft {
        display: flex;
        gap: 40px;
        border-right: 1px solid #fff;
        padding-right: 40px;
        height: 30px;
        margin-top: 5px;
        padding-top: 4px;
      }

      .roiGreenTabRight {
        display: flex;
        gap: 40px;
        height: 30px;
        margin-top: 5px;
        padding-top: 4px;
      }

      // span {
      //   font-weight: 800;
      // }
    }
  }

  .paneHeight {
    min-height: 60vh !important;
    height: 60vh !important;
    overflow: scroll;
    margin-bottom: 7rem;
  }

  .form-select {
    background-position: right 0.2rem center;
  }

  .cbp_tmtimeline>li .cbp_label {
    margin: 0 0 30px 18%;
  }

  //Solution page ends

  //Dashboard starts
  .table-body {
    width: 100% !important;
  }

  .dash-card-graph {
    padding: 0.6rem 0.5rem 0.6rem 0.5rem;
  }

  .outerTabDiv .table-row3 {
    padding: 10px 15px 10px 1px;
  }

  .chartHeader .chartHeaderDate .chartHeaderYear {
    width: 2.5rem;
    line-height: 12px;
  }

  .user-info {
    justify-content: left;
    align-items: self-start;
  }

  .pending-recs {
    margin-top: 4rem;
  }

  .carousel-indicators {
    bottom: -2.8rem;
  }

  .carousel-indicators button {
    background-color: #869791 !important;
    border-radius: 30px;
    display: block;
    height: 10px !important;
    margin: 5px 7px;
    transition: opacity 0.2s ease;
    width: 10px !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
    margin-right: 10px !important;
  }

  .carousel-indicators .active {
    background: #810055 !important;
    width: 20px !important;
  }

  //dashboard ends

  .blue-arrow {
    float: right;
    padding-top: 5%;
    margin-left: 5%;
    margin-bottom: 4%;
  }

  .top-18 {
    top: 20% !important;
  }

  .blue-arrow.uparrow {
    margin: -5% 14% 0 8%;
  }

  .col-md-8.trackreportheading {
    margin: 0% 0 0 4%;
    padding: 0 0 0 0;
  }

  .blue-arrow.downarrow {
    float: right;
    margin: -2% 0 3% 9%;
  }

  .solun-tab1-height {
    height: 95px;
  }

  .login-form-check {
    padding-left: 1rem !important;
  }

  .modalIndex {
    z-index: 999;
  }

  .modalHeight {
    overflow: auto;
  }
}

//end by sakshi for mobile layout

// @media (max-width: 767px) {
//   .modal-title {
//     font-size: 18px;
//   }

//   .showMore .showButton {
//     display: none !important;
//   }

//   .reset-btn.reset-btn-primary {
//     padding-right: 0 !important;
//   }

//   .centertext1 {
//     text-align: center;
//     font-size: 60%;
//     padding: 0.2rem;
//   }

//   .justify-content-start1.modal-footer {
//     margin: 4.6rem 0 3rem 0 !important;
//     justify-content: flex-start !important;
//   }

//   .access-menu-data {
//     color: #6f7381 !important;
//     // width: 45% !important;
//     // overflow: scroll !important;
//     text-overflow: clip !important;
//   }

//   .access-menu-data-show-more {
//     display: -webkit-box;
//     -webkit-box-orient: vertical;
//     -webkit-line-clamp: 3;
//   }

//   .btn-close {
//     margin-top: -10% !important;
//   }

//   .head-logo.ms-2 {
//     width: 46% !important;
//     margin-bottom: 2% !important;
//   }

//   .modal-dialog.modal-lg {
//     .form-label {
//       margin-bottom: 0.3rem !important;
//     }

//     .form-control {
//       margin-bottom: 0.5rem !important;
//     }
//   }

//   .form-control1-padding {
//     width: 100% !important;
//   }

//   .form-control1-padding11 {
//     width: 55% !important;
//   }

//   .modal-dialog {
//     margin: 2rem;

//     .modal-content {
//       .d-flex.flex-column.align-items-end.me-2 {
//         .btn-close {
//           margin-top: 2% !important;
//         }
//       }
//     }

//     .btn-close {
//       margin-top: -18% !important;
//     }
//   }

//   .align-text {
//     width: 40%;
//     word-break: break-word;
//     margin-top: 0.5rem;
//     //padding-left: 20px;
//   }

//   .align-text1 {
//     width: 48%;
//     word-break: break-word;
//   }

//   .align-text2 {
//     width: 40%;
//     word-break: break-word;
//     margin-top: 0.5rem;
//     color: #000000;
//   }

//   .m-0.list-unstyled {

//     .place-center {

//       .application-form.d-block {

//         .g-0.mt-1.row {

//           .place-center.col-lg-2.col-md-2.col-sm-2.col-2 {

//             .list-bullet {
//               margin-left: 17% !important;
//             }
//           }
//         }
//       }
//     }
//   }

//   .application-form.d-block {
//     .g-0.mt-1.row {
//       .me-3.col-6.col-md-5 {
//         width: 43% !important;
//       }

//       .col-2.col-md-4 {
//         width: 32% !important;

//         .placeHolderInput1.form-control2.form-select1.inputTextWhite.unitBox.form-select {

//           line-height: 1.5 !important;
//           font-size: 12px !important;
//         }
//       }
//     }
//   }

//   .card-space {
//     margin-bottom: 8rem;
//   }

//   .tableWrapperCompressorModal {
//     .headerSelect {
//       border: none;
//       background: #c5cadb;
//       border-radius: 5px;
//       width: 180px;
//     }
//   }

//   .tableWrapperCompressorModal .table-cell {
//     min-width: 100%;
//     max-width: 100%;
//     display: -webkit-inline-box;

//   }

//   span.right-allign {
//     text-align: left;
//   }

//   .table-row2 {
//     min-height: 48px;
//     background-color: #eff3fc;
//     margin-top: 0px;
//     font-weight: 500;
//     transition: all 300ms;
//     border-width: 5px 5px 0px 0px;
//     border-color: #000;
//     position: relative;
//     display: block;
//   }

//   // input {
//   //   width: -webkit-fill-available;
//   //   //width: 70%;
//   // }

//   .btn-sm {
//     padding: 0.15rem 0.25rem;
//     font-size: .875rem;
//     border-radius: 0.2rem;
//   }

//   .red-tick {
//     width: 16px;
//     height: 16px;
//     border-radius: 50%;
//     background: #ff0000;
//     font-size: 7px;
//     color: #fff;
//     text-align: center;
//     line-height: 16px;
//     margin: 20px 0;
//     position: absolute;
//     margin-left: 5%;
//     margin-top: 23px;
//   }

//   .green-tick {
//     width: 16px;
//     height: 16px;
//     border-radius: 50%;
//     background: #9bbc44;
//     font-size: 7px;
//     color: #fff;
//     text-align: center;
//     line-height: 16px;
//     margin: 20px 0;
//     position: absolute;
//     margin-left: 5%;
//     margin-top: 24px;
//   }

//   .tableWrapperCompressorModal {
//     .cellActionCustom {
//       position: static;
//       opacity: 1;
//     visibility: visible;
//     }
//   }
// }

// @media (max-width:400px) {

//   .m-0.list-unstyled {

//     .place-center {

//       .application-form.d-block {

//         .g-0.mt-1.row {

//           .place-center.col-lg-2.col-md-2.col-sm-2.col-2 {

//             // .list-bullet {
//             //   margin-left: 23% !important;
//             // }

//             .margin-top-15 {
//               margin-top: 15%;
//             }

//             .margin-bottom-30 {
//               margin-bottom: 30%;
//             }
//           }
//         }
//       }
//     }
//   }

//   .margin-left-35 {
//     margin-left: 7rem !important;
//   }
// }

// @media (min-width:401px) and (max-width:701px) {
//   .margin-left-35 {
//     margin-left: 7rem !important;
//   }
// }

@media (min-width: 768px) and (max-width: 1019px) {
  .left-margin-none {
    margin-bottom: 16px;
    margin-left: 22px !important;
  }

  .display-block {
    display: block !important;
    margin-left: 12px !important;
  }
}

.dispaly-none {
  display: none;
}

.collapsible-header {
  background-color: #dfe5f8 !important;
  padding-left: 0 !important;
}

.collapse-header {
  height: 2rem;
}

.accordion-item>.collapse {
  display: block !important;
}

.top-18 {
  top: 18% !important;
}

// .drop-animate{
//   animation: growDown 300ms ease-in-out
// }
// forwards{
//   transform-origin: top center;
// }

// @keyframes growDown {
//   0% {
//     transform: scaleY(0);
//   }
//   30% {
//     transform: scaleY(0.5);
//   }
//   80% {
//     transform: scaleY(1.1);
//   }
//   100% {
//     transform: scaleY(1);
//   }
// }

.history-box {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 100% !important;
}

.accordion-button::after {
  margin-left: 2rem !important;
  color: #264ae4 !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("../src/icons/calendar.svg") no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

.informationLabel {
  height: 33px;
  background-color: #b0dee6;
  opacity: 1;

  label {
    text-align: left;
    font: normal normal bold 12px/14px 'G&B-BodyCopy';
    letter-spacing: 0px;
    color: #3593ff;
    opacity: 1;
  }
}

//Dashboard starts
.table-body {
  width: 100% !important;
}

.dash-card-graph {
  padding: 0.6rem 0.5rem 0.6rem 0.5rem;
  border: 1px solid #f1f1ed;
}

.outerTabDiv .table-row3 {
  padding: 10px 15px 10px 1px;
}

.chartHeader .chartHeaderDate .chartHeaderYear {
  width: 2.5rem;
  line-height: 0.8rem;
  color:#707070
}

.carousel-control-next,
.carousel-control-prev {
  display: none !important;
}

//dashboard ends
// }
//end by sakshi for mobile layout

// @media (max-width: 767px) {
//   .modal-title {
//     font-size: 18px;
//   }

//   .showMore .showButton {
//     display: none !important;
//   }

//   .reset-btn.reset-btn-primary {
//     padding-right: 0 !important;
//   }

//   .centertext1 {
//     text-align: center;
//     font-size: 60%;
//     padding: 0.2rem;
//   }

//   .justify-content-start1.modal-footer {
//     margin: 4.6rem 0 3rem 0 !important;
//     justify-content: flex-start !important;
//   }

//   .access-menu-data {
//     color: #6f7381 !important;
//     // width: 45% !important;
//     // overflow: scroll !important;
//     text-overflow: clip !important;
//   }

//   .access-menu-data-show-more {
//     display: -webkit-box;
//     -webkit-box-orient: vertical;
//     -webkit-line-clamp: 3;
//   }

//   .btn-close {
//     margin-top: -10% !important;
//   }

//   .head-logo.ms-2 {
//     width: 46% !important;
//     margin-bottom: 2% !important;
//   }

//   .modal-dialog.modal-lg {
//     .form-label {
//       margin-bottom: 0.3rem !important;
//     }

//     .form-control {
//       margin-bottom: 0.5rem !important;
//     }
//   }

//   .form-control1-padding {
//     width: 100% !important;
//   }

//   .form-control1-padding11 {
//     width: 55% !important;
//   }

//   .modal-dialog {
//     margin: 2rem;

//     .modal-content {
//       .d-flex.flex-column.align-items-end.me-2 {
//         .btn-close {
//           margin-top: 2% !important;
//         }
//       }
//     }

//     .btn-close {
//       margin-top: -18% !important;
//     }
//   }

//   .align-text {
//     width: 40%;
//     word-break: break-word;
//     margin-top: 0.5rem;
//     //padding-left: 20px;
//   }

//   .align-text1 {
//     width: 48%;
//     word-break: break-word;
//   }

//   .align-text2 {
//     width: 40%;
//     word-break: break-word;
//     margin-top: 0.5rem;
//     color: #000000;
//   }

//   .m-0.list-unstyled {

//     .place-center {

//       .application-form.d-block {

//         .g-0.mt-1.row {

//           .place-center.col-lg-2.col-md-2.col-sm-2.col-2 {

//             .list-bullet {
//               margin-left: 17% !important;
//             }
//           }
//         }
//       }
//     }
//   }

//   .application-form.d-block {
//     .g-0.mt-1.row {
//       .me-3.col-6.col-md-5 {
//         width: 43% !important;
//       }

//       .col-2.col-md-4 {
//         width: 32% !important;

//         .placeHolderInput1.form-control2.form-select1.inputTextWhite.unitBox.form-select {

//           line-height: 1.5 !important;
//           font-size: 12px !important;
//         }
//       }
//     }
//   }

//   .card-space {
//     margin-bottom: 8rem;
//   }

//   .tableWrapperCompressorModal {
//     .headerSelect {
//       border: none;
//       background: #c5cadb;
//       border-radius: 5px;
//       width: 180px;
//     }
//   }

//   .tableWrapperCompressorModal .table-cell {
//     min-width: 100%;
//     max-width: 100%;
//     display: -webkit-inline-box;

//   }

//   span.right-allign {
//     text-align: left;
//   }

//   .table-row2 {
//     min-height: 48px;
//     background-color: #eff3fc;
//     margin-top: 0px;
//     font-weight: 500;
//     transition: all 300ms;
//     border-width: 5px 5px 0px 0px;
//     border-color: #000;
//     position: relative;
//     display: block;
//   }

//   // input {
//   //   width: -webkit-fill-available;
//   //   //width: 70%;
//   // }

//   .btn-sm {
//     padding: 0.15rem 0.25rem;
//     font-size: .875rem;
//     border-radius: 0.2rem;
//   }

//   .red-tick {
//     width: 16px;
//     height: 16px;
//     border-radius: 50%;
//     background: #ff0000;
//     font-size: 7px;
//     color: #fff;
//     text-align: center;
//     line-height: 16px;
//     margin: 20px 0;
//     position: absolute;
//     margin-left: 5%;
//     margin-top: 23px;
//   }

//   .green-tick {
//     width: 16px;
//     height: 16px;
//     border-radius: 50%;
//     background: #9bbc44;
//     font-size: 7px;
//     color: #fff;
//     text-align: center;
//     line-height: 16px;
//     margin: 20px 0;
//     position: absolute;
//     margin-left: 5%;
//     margin-top: 24px;
//   }

//   .tableWrapperCompressorModal {
//     .cellActionCustom {
//       position: static;
//       opacity: 1;
//     visibility: visible;
//     }
//   }
// }

// @media (max-width:400px) {

//   .m-0.list-unstyled {

//     .place-center {

//       .application-form.d-block {

//         .g-0.mt-1.row {

//           .place-center.col-lg-2.col-md-2.col-sm-2.col-2 {

//             // .list-bullet {
//             //   margin-left: 23% !important;
//             // }

//             .margin-top-15 {
//               margin-top: 15%;
//             }

//             .margin-bottom-30 {
//               margin-bottom: 30%;
//             }
//           }
//         }
//       }
//     }
//   }

//   .margin-left-35 {
//     margin-left: 7rem !important;
//   }
// }

// @media (min-width:401px) and (max-width:701px) {
//   .margin-left-35 {
//     margin-left: 7rem !important;
//   }
// }

@media (min-width: 768px) and (max-width: 1019px) {
  .left-margin-none {
    margin-bottom: 16px;
    margin-left: 22px !important;
  }

  .display-block {
    display: block !important;
    margin-left: 12px !important;
  }
}

.dispaly-none {
  display: none;
}

.collapsible-header {
  background-color: #fff !important;
  padding-left: 0 !important;
}

.collapse-header {
  height: 2rem;
}

.top-18 {
  top: 16% !important;
}

// .drop-animate{
//   animation: growDown 300ms ease-in-out
// }
// forwards{
//   transform-origin: top center;
// }

// @keyframes growDown {
//   0% {
//     transform: scaleY(0);
//   }
//   30% {
//     transform: scaleY(0.5);
//   }
//   80% {
//     transform: scaleY(1.1);
//   }
//   100% {
//     transform: scaleY(1);
//   }
// }

.history-box {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 100% !important;
}

.accordion-button::after {
  margin-left: 2rem !important;
  color: #264ae4 !important;
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("../src/icons/calendar.svg") no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

.informationLabel {
  background-color: #f5d8ad;
  opacity: 1;

  label {
    text-align: left;
    font: normal normal bold 12px/14px 'G&B-BodyCopy';
    letter-spacing: 0px;
    color: #d16f0d;
    opacity: 1;
  }
}

.informationSpan {
  text-align: left;
  font: normal normal bold 12px/14px 'G&B-BodyCopy';
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.TableB {
  height: 60px;
  background: #707070 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 4px;
  padding-top: 14px;
  padding-bottom: 10px;
}

.tableB-header {
  text-align: left;
  font: normal normal 14px/16px 'G&B-Headline';
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.tableB-row {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 4px;
  margin-top: 7px;
  border:1px solid #f1f1ed;
}

.tableB-text {
  font-size: 14px;
  text-align: left;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tableB-input {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.executive-ans {
  font: normal normal medium 14px/16px 'G&B-BodyCopy';
  letter-spacing: 0px;
  // font-weight: 800;
  font-size: small;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #810055;
  background-color: #fff;
}

.executive-que {
  letter-spacing: 0px;
  // font-weight: 200;
  font-size: small;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #810055;
  background-color: #fff;
  border-right: 1px solid #f1f1ed;
}

.executive-row {
  background: #fff 0% 0% no-repeat padding-box;
  opacity: 1;
  border: 1px solid #810055;
}

.executive-head {
  height: 40px;
  background-color: #fff;
  color:#810055;
}

.executive-head-text {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #810055;
  // font-weight: 800;
}

.executive-head-smalltext {
  // font-weight: 200;
  color: 707070;
  font-size: smaller;
  padding-top: 10px;
  padding-bottom: 10px;
}

.img2 {
  position: fixed;
  margin-left: -24px;
  margin-top: 2px;
}

.img1 {
  position: relative;
}

.list1:hover .img2 {
  opacity: 0;
}

.list2:hover .img2 {
  opacity: 0;
}

.list3:hover .img2 {
  opacity: 0;
}

.list4:hover .img2 {
  opacity: 0;
}

.list5:hover .img2 {
  opacity: 0;
}

.list6:hover .img2 {
  opacity: 0;
}

.nav-list-item.active1 .img2 {
  opacity: 0;
}

.currency-row {
  // font-weight: 600;
  font-size: 14px;
  color: #707070;
  padding-top: 1.5rem;
}

// .react-flow{
// overflow-y: auto !important;
// overflow-x: auto !important;

// }
.pie-scroll {
  height: 14.5rem;
  overflow-y: auto;
  overflow-x: clip;
}

//****-spinner vishal-****//
@keyframes sk-bouncedelay {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.SiteLoader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 999;
  text-align: center;
}

.spinner {
  position: absolute;
  top: 50%;
  margin: 0 auto;
  width: 70px;
  text-align: center;
  z-index: 999;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.spinner>div:first-child {
  background: #810055;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner>div {
  width: 18px;
  height: 18px;
  display: inline-block;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: 1.4s ease-in-out infinite both sk-bouncedelay;
  animation: 1.4s ease-in-out infinite both sk-bouncedelay;
}

.spinner>div:nth-child(2) {
  background: #810055;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.spinner>div:last-child {
  background: #810055;
}

//****-spinner vishal-****//

@media (min-width: 768px) {
  .modalHeight {
    height: 200px;
    overflow: auto;
  }
}

.modalDefaultHeight {
  height: 615px;
  overflow: auto;
}

.proposedSavings {
  background: #fff;
  border: 1px solid #f1f1ed;
  border-radius: 5px;
}

//=================================
.ag-header-container{
  background-color: #ffffff;
}
.ag-theme-material .ag-header{
  border-bottom: none !important;
}
.ag-theme-material .ag-header-cell:hover {
  background-color: #707070 !important; 
}
.side-bar.closed .sidebar-body{
  // border: 1px solid #f1f1ed;
  border-right:1px solid #f1f1ed;
  border-bottom: 1px solid #f1f1ed;
}
.side-bar .sidebar-body{
  // border: 1px solid #f1f1ed;
  border-right:1px solid #f1f1ed;
  border-bottom: 1px solid #f1f1ed;
}
.side-bar.closed .sidebar-head{
  border: 1px solid #f1f1ed;
}
.side-bar .sidebar-head{
  border: 1px solid #f1f1ed;
}
.ag-paging-row-summary-panel{
  color: #707070;
}
.ag-paging-page-summary-panel{
  color: #707070;
}
.sub-menu-item:hover{
  color: #810055;
}
// .global-search-container img{
//   height: 32px;
//   width: 32px;
// }
.btn-outline-primary{
border-color: #810055;
color: #810055;
background-color: #fff;
border-radius: 20px;
}
.btn-outline-primary:hover{
  background-color: #fff;
  border-color: #810055;
  color: #810055;
}
.form-control{
  border-color: #f1f1ed;
}
.form-box:hover{
  color: #810055;
}
.ag-theme-material .ag-row-selected {
  background-color: #f9f2f6 !important;
  border-color: #707070;
}
// .table-cell .input #flexRadioDefault1{
//   background-color: #810055 !important;
// }
input[type="radio"]:checked {
  accent-color: #810055;
}
// .custom-close-btn .btn-close:focus{
//   box-shadow: none;
// }
.modal-header .btn-close{
  box-shadow: none;
  // filter:invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
}
// .modal-header .btn-close:hover{
  // color: #810055 !important;
  // filter:invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
// }
.modal-title{
  font-family: 'G&B-Headline';
}
input[type="checkbox"]:checked{
 background-color: #810055;
}
.btn-outline-primary:focus{
  box-shadow: none !important;
  }
  .ag-body-viewport{
    background-color: #fff;
}
.border{
  border: 1px solid #f1f1ed !important;
}
.form-control:focus{
  box-shadow: none;
  border-color: #707070;
}
.ag-theme-material .ag-header-row .ag-icon{
  color:#fff
}
.ag-theme-material input[class^=ag-][type=text], .ag-theme-material textarea[class^=ag-]:focus {
  border-bottom: 2px solid #e2e2e2;
  border-bottom-color: var(--ag-border-color, #e2e2e2);
}
  .ag-theme-material .ag-filter-select .ag-picker-field-wrapper{
    border: 1px solid #f1f1ed;
  }
  .form-select-ba option:hover {
    background-color: #707070 !important; 
  }
  .form-select-ba {
    box-shadow: none !important;
  }
  .ag-theme-material .ag-radio-button-input-wrapper.ag-checked:after{
    color:#810055 !important;
  }
  .filter-col input:focus {
    border-bottom: 2px solid #810055 !important;
  }
  .ag-theme-material .ag-popup-child:not(.ag-tooltip-custom) {
     box-shadow: none ;
}
.ag-theme-material .ag-header-row{
  font-weight: normal !important;
}
.form-select:disabled{
  background-color: #fff;
}
.modal-content{
color: #707070;
}
.tableWrapperCompressorModal{
  input:hover{
    background-color: #fff;
    border: 1px solid #f1f1ed;
  }
}
.tableWrapperCompressorModal{
  input:focus{
    background-color: #fff;
    border: 1px solid #f1f1ed;
  }
}
.tableWrapperCompressorModal .headerSelect:focus {
 border: 1px solid #f1f1ed;
}
.no-hover:hover {
  background-color: inherit !important;
}
.border-end{
  border-right: 1px solid #f1f1ed !important;
}
input[type="checkbox"]{
  border: 1.5px solid #707070;
 }
 label.label{
   color: #707070;
 }
 .breadcrumb-item+.breadcrumb-item:before {
  color: #707070;
  content: ">" !important;
  float: left;
  padding-right: .5rem;
}
.ag-theme-material .ag-checkbox-input-wrapper:after {
  color: #707070;
  color: var(--ag-checkbox-unchecked-color, #707070);
  content: "\f108";
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  box-shadow: none;
}
.ag-theme-material .ag-checkbox-input-wrapper:focus:after {
  box-shadow: none;
}
.ag-theme-material .ag-checkbox-input-wrapper input:focus {
  box-shadow: none;
}
#AddCompressor-table-undefined {
  margin-bottom: 1rem;
}
input.placeHolderInput.form-control2.inputTextWhite.form-control::placeholder {
  color: #fff !important;
}
.place-center.display-block {
  margin-left: 7rem;
}